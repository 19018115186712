import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BXEngine } from "src/BXEngine";
import { useAppState } from "src/features/appState/hooks";
import { removeAppField } from "src/features/endUser/endUserSlice";
import store from "src/store/store";
import { BxPatterns } from "src/utils/BXValidate/BxPatterns";
import { getLastKeyFromObject } from "src/utils/generalUtils";
import { useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import { BXIcon } from "../Icon";
import BXModal from "../Modal";

type Props = {
  action?: any;
  onSelectRow?: any;
  item?: any;
  actionSourceType?: any;
  selectedView: any;
  openView?: boolean;
  buttonProps?: any;
  icon?: any;
  views?: any;
  showHeader?: boolean;
  withoutLabel?: boolean;
  modalSize?: string;
  iconColor?: string;
  __data?: any;
  id?: string;
  prevVideoCardId?: string;
  parentIds?: any[];
  buttonComponent?: any;
  index?: any;
  pageId?: any;
  viewName?: string;
  isUserInput?: boolean;
  path?: string;
  clearActionView?: any;
  type?: string;
  keyComp?: string;
  isConfirmationModal?: boolean;
  isCustomWidthConfirm?: any;
  customModalWidthConfirm?: any;
  collectionId?: any;
  sharedPageId?: any;
};

const ViewerModal = (props: Props) => {
  const {
    id,
    item,
    selectedView,
    openView,
    action,
    onSelectRow,
    actionSourceType,
    buttonProps,
    icon = null,
    withoutLabel,
    views,
    modalSize = "lg",
    showHeader = true,
    __data = {},
    iconColor,
    parentIds = [],
    prevVideoCardId,
    buttonComponent,
    index,
    viewName,
    isUserInput,
    path,
    clearActionView,
    keyComp,
    type, //To specify the view type when the source is not an action
    isConfirmationModal,
    isCustomWidthConfirm,
    customModalWidthConfirm,
    collectionId,
    sharedPageId,
  } = props;
  let { pageId } = props;

  const { palette } = useTheme();
  const [isDirty, setIsDirty] = useState(false);
  const { useReplaceDataPlaceholdersUseSelector } = useReplaceDataPlaceholders({ viewName });
  const title = useReplaceDataPlaceholdersUseSelector({
    queryString: action?.label,
    item,
    pageId,
    __data,
    selectedView,
  });

  const dispatch = useDispatch();
  const { setValue, getValue } = useAppState();
  const isDirtyView = getValue(`${pageId}.${selectedView?.info?.viewName}.isDirty`, { pageId, viewName: selectedView?.info?.viewName });

  useEffect(() => {
    setIsDirty?.(isDirtyView);
  }, [isDirtyView]);

  if (!pageId && selectedView?.info?.viewName) {
    //Layout case, assign pageId to current page
    const state = store.getState();
    pageId = Object.keys(state.endUser.application.pages)[0];
  }

  return (
    <BXModal
      label={title}
      open={openView}
      icon={
        icon || (
          <BXIcon
            icon={
              (action?.iconConfig?.visibility === "PUBLIC" && action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                ? action?.iconConfig?.url
                : action?.iconConfig?.icon) || action?.icon
            }
            width={16}
            height={16}
            color={iconColor || palette.text.primary}
          />
        )
      }
      buttonProps={{
        variant: "contained",
        startIcon: (
          <BXIcon
            icon={
              (action?.iconConfig?.visibility === "PUBLIC" && action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                ? action?.iconConfig?.url
                : action?.iconConfig?.icon) || action?.icon
            }
            width={20}
            height={20}
            color={iconColor || palette.text.primary}
          />
        ),
        onClick: () => onSelectRow?.(item, true),
        ...buttonProps,
      }}
      title={title}
      maxWidth={modalSize}
      isCustomWidth={action?.isCustomWidth || isCustomWidthConfirm}
      disableDefaultPadding={action?.disableDefaultPadding}
      customModalWidth={action?.customModalWidth || customModalWidthConfirm}
      showHeader={showHeader}
      withoutLabel={withoutLabel}
      breakpointSettings={action?.isBreakpointEnabled ? action?.breakpointSettings : null}
      isDirty={isDirty}
      closeOnlyViaDismiss={action?.closeViaDismissButton}
      onClose={() => {
        const stateView = getValue(`${pageId}.${selectedView?.info?.viewName}`, { pageId, viewName: selectedView?.info?.viewName });
        setValue(`${pageId}.${viewName}.${keyComp}.response`, stateView, { pageId, viewName });
        setTimeout(() => {
          dispatch(
            removeAppField({
              name: `${pageId}.${selectedView?.info?.viewName}`,
              pathVariables: { pageId, viewName: selectedView?.info?.viewName },
            })
          );
        }, 300);
        clearActionView?.();
      }}
    >
      {(handleClose: Function) => {
        return (
          <>
            <BXEngine
              auth={{}}
              path={[path, action?.label].join(".")}
              setIsDirty={!isUserInput ? setIsDirty : undefined}
              showModalHeader={showHeader}
              page={{ views, layout: selectedView, id: pageId } as any}
              layout={[{ ...selectedView, type: actionSourceType?.type || type }]}
              isVisible
              collectionId={selectedView?.sharedCollectionId || collectionId}
              sharedPageId={selectedView?.sharedPageId || sharedPageId}
              selectedViewId={item?.id}
              index={index}
              __data={{
                ...__data,
                [(getLastKeyFromObject(__data) || "") + "#."]: viewName,
              }}
              closeModal={(data: any, _isDirty = isDirty) => {
                if (data) {
                  onSelectRow?.(data);
                }

                handleClose(null, null, _isDirty);
              }}
              prevVideoCardId={prevVideoCardId}
              parentIds={[...parentIds, id]}
              isConfirmationModal
            />
          </>
        );
      }}
    </BXModal>
  );
};

export default ViewerModal;
