/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-loop-func */
import { LoadingButton } from "@mui/lab";
import { Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import _, { isNumber } from "lodash";
import { FC, useMemo } from "react";
import { osVersion } from "react-device-detect";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BXIcon } from "src/components/BXUI/Icon";
import config, { appVersion } from "src/config";
import { useAppState } from "src/features/appState/hooks";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { selectComponentById } from "src/features/endUser/selectors";
import store, { RootState } from "src/store/store";
import { UIElement } from "src/types/UIElement";
import { reverseDataOfObjectValues } from "src/utils/generalUtils";
import { getItemClosestProp } from "src/views/pages/BuildX/FormBuilder/utils";
import { v4 as uuid } from "uuid";
import { BXConfirmationDialog } from "../AlertDialog/ConfirmationDialog";
import BXModal from "../Modal";
import { ViewerModal } from "../viewerModal";
import CSVProgressDialog from "./CSVProgressDialog";
import CreatePayload from "./CreatePayload";
import TableAction from "./TableAction";
import { useActionHandler } from "./useActionHandler";

//Predefined Properties for responses.
const BXPredefinedSyntaxProperties = new Set(["_status", "_body", "_headers", "_messages", "_message", "_pagination", "_request"]);

//Data or Response JSON Path handling
function extractPropertyForSyntax(pathName: string) {
  // Regular expression to match patterns like .data/response[<number>].property or .data/response.property
  const regex = /(?:^|\W)(data(?:\[\d+\])?|responses(?:\[\d+\])?|response)\.(\w+)/;

  const match = pathName.match(regex);
  if (match) {
    const prefixPath = match[1];
    const property = match[2];

    return { prefixPath, property };
  }

  return null;
}

//Extract the corresponding property value based on current breakpoint
function extractResponsiveProperty(prop, breakpoint) {
  const breakpoints = ["xl", "lg", "md", "xs"];
  if (typeof prop === "object" && prop !== null) {
    // If the property contains breakpoint keys, resolve the value
    if (breakpoints.some(key => key in prop)) {
      if (prop[breakpoint] !== undefined) {
        return prop[breakpoint];
      }

      if (prop.lg !== undefined) {
        return prop.lg;
      }

      return getItemClosestProp(prop, breakpoint);
    }
  }

  return prop;
}

export const useReplaceDataPlaceholders = (props?: any) => {
  const { viewName } = props || {};
  const viewsState = useBuildxProviderValue("viewsState");
  const queriesStateGraphQL = useBuildxProviderValue("queriesStateGraphQL");
  const currentBreakpoint = useBuildxProviderValue("currentBreakpoint");

  const { getValues, setValue, watch, getValue, getValueRecursively, removeValue, mergeValue } = useAppState();

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  location.pathname = window.location.pathname;
  location.state = location.state;
  const searchParams = new URLSearchParams(location.search);
  const queryParams = {};
  searchParams.forEach((value, key) => {
    queryParams[key] = value;
  });

  const replaceDataPlaceholdersProps = {
    queriesStateGraphQL,
    viewsState,
    viewName,
    getValues,
    getValue,
    getValueRecursively,
    setValue,
    removeValue,
    mergeValue,
    watch,
    navigate,
    useSelector,
    location: {
      params,
      queryParams,
      pathname: location.pathname,
      host: window.location.host,
      url: window.location.href,
      state: location.state,
    },
    currentBreakpoint,
  };

  return {
    replaceDataPlaceholders: props => replaceDataPlaceholders({ ...props, ...replaceDataPlaceholdersProps }),
    useReplaceDataPlaceholdersUseSelector: props => useReplaceDataPlaceholdersUseSelector({ ...props, ...replaceDataPlaceholdersProps }),
    replaceDataPlaceholdersRecursively: props => replaceDataPlaceholdersRecursively({ ...props, ...replaceDataPlaceholdersProps }),
    useReplaceDataPlaceholdersRecursively: props => useReplaceDataPlaceholdersRecursively({ ...props, ...replaceDataPlaceholdersProps }),
    updateDataPlaceholders: props => updateDataPlaceholders({ ...props, ...replaceDataPlaceholdersProps }),
  };
};

export const updateDataPlaceholders = (props: any) => {
  const {
    queryString,
    pageId,
    getValue,
    setValue,
    removeValue,
    index: itemIndex,
    viewName,
    $action,
    $payload,
    navigate,
    location,
    currentBreakpoint,
    pageIdFromLayout,
  } = props;

  let result = queryString;

  const deleteRepeatedStateRecursively = (children, pageId, preDefinedVariable, deletedIndex) => {
    children.forEach(child => {
      const childElement = selectComponentById(store.getState(), pageId, preDefinedVariable, child);
      const stateName = `${pageId}.${preDefinedVariable}.${child}.state[${deletedIndex}]`;
      const initialValueName = `${pageId}.${preDefinedVariable}.${child}.initialValue[${deletedIndex}]`;
      const errorName = `${pageId}.${preDefinedVariable}.${child}.errors.error[${deletedIndex}]`;
      const hasErrorName = `${pageId}.${preDefinedVariable}.${child}.errors.hasError[${deletedIndex}]`;
      const errorListName = `${pageId}.${preDefinedVariable}.${child}.errors.list[${deletedIndex}]`;

      removeValue(stateName, { pageId, viewName });
      removeValue(initialValueName, { pageId, viewName });
      removeValue(errorName, { pageId, viewName, repeatedComponentIndex: deletedIndex });
      removeValue(hasErrorName, { pageId, viewName });
      removeValue(errorListName, { pageId, viewName });

      if (childElement && Array.isArray(childElement.children) && childElement.children.length > 0) {
        deleteRepeatedStateRecursively(childElement.children, pageId, preDefinedVariable, deletedIndex);
      }
    });
  };

  if (typeof queryString === "boolean" || typeof queryString === "number") return queryString;
  const placeholders = queryString?.match(/\{(.*?)\}/g);

  placeholders?.forEach(function (placeholder: any) {
    let phText = "";

    const index = placeholder.split("").findIndex((key: string) => key == ".");
    phText = placeholder.substring(index + 1, placeholder.length - 1);

    let preDefinedVariable = placeholder.substring(1, index);
    if (preDefinedVariable === "this") {
      preDefinedVariable = viewName;
    }
    let deletedIndex: null | number = null;
    phText = phText.replace(/\[\*\s*([+-]?\d+)?\]/g, function (match, number, offset) {
      let indexAdjustment = number ? parseInt(number, 10) : 0;
      let adjustedIndex = itemIndex + indexAdjustment;
      deletedIndex = adjustedIndex;
      return `[${adjustedIndex}]`;
    });

    let key = `${pageId}.${preDefinedVariable}.${phText}`;

    if ($action === "DELETE") {
      if (preDefinedVariable == "$global") {
        if (phText.startsWith("localStorage.")) {
          phText = phText.replace("localStorage.", "");
          localStorage.removeItem(phText);
        }
      } else {
        if (phText.includes("repeatedState")) {
          const keyRepeated = phText.split(".")[0];
          const parentComp = selectComponentById(store.getState(), pageId, preDefinedVariable, keyRepeated);
          const repeatedIdDeleted = parentComp?.repeatedState[deletedIndex || 0] || {};
          if (repeatedIdDeleted?.isAdded == false) {
            const repeatedStateDeleted = (parentComp?.repeatedStateDeleted || 0) + 1;
            let componentKey = key.split(".").slice(0, 3).join(".");
            setValue(`${componentKey}.repeatedStateDeleted`, repeatedStateDeleted, { pageId, viewName: preDefinedVariable });
          }

          deleteRepeatedStateRecursively(parentComp.children, pageId, preDefinedVariable, deletedIndex);
        }

        removeValue(key, { pageId, viewName });
      }
    } else if ($action === "ADD") {
      const data = [...(getValue(key, { pageId, viewName }) || [])];
      if (key.split(".").pop() === "state") {
        const [pageId, viewName, componentKey, ..._rest] = key.split(".") || [];
        const viewState = _.cloneDeep(getValue(`${pageId}.${viewName}`, { pageId, viewName }));
        const baseComponent = _.get(viewState, [componentKey]);
        const baseElement = _.get(baseComponent, ["baseElement"]);
        if (baseElement) {
          if (Array.isArray(data)) {
            const baseKey = `${componentKey}.state[${data.length}]`;
            Object.keys(baseElement).forEach(childKey => {
              if (childKey !== componentKey) {
                const childParent = _.clone(_.get(baseElement, [childKey, "parent"]));
                const newParentPath = `${baseKey}.${childParent}`;
                _.set(baseElement, [childKey, "parent"], _.clone(newParentPath));
              }
              const childPath = [childKey, "props", "basePath"];
              _.set(baseElement, childPath, _.clone(baseKey));
            });
            data.push(baseElement);
          }
          setValue(`${key}`, data, { pageId, viewName });
        }
      } else {
        if (Array.isArray(data)) {
          const newItem = {
            id: uuid(),
            isDeleted: false,
            isAdded: true,
          };
          data.push(newItem);
        }

        setValue(`${key}`, data, { pageId, viewName });
      }
    } else if ($action === "UPDATE") {
      if (preDefinedVariable === "$app") {
        setValue(`application.${phText}`, $payload, { pageId, viewName });
      } else if (preDefinedVariable == "$global") {
        if (phText.startsWith("location.state")) {
          const currentState = typeof location.state === "object" && location.state !== null ? location.state : {};
          phText = phText.replace("location.state", "");
          if (phText) {
            phText = phText.startsWith(".") ? phText.slice(1) : phText;
            navigate(location.pathname, {
              state: {
                ...currentState,
                [phText]: $payload,
              },
            });
          } else {
            navigate(location.pathname, {
              state: $payload,
            });
          }
        } else if (phText.startsWith("localStorage.")) {
          phText = phText.replace("localStorage.", "");
          if (typeof $payload == "object") {
            localStorage.setItem(phText, JSON.stringify($payload));
          } else {
            localStorage.setItem(phText, $payload);
          }
        }
      } else {
        //Resolve responsive style or rendering property path
        if (key.includes(".props.") || key.includes(".config.")) {
          key = `${key}.${currentBreakpoint}`;
        }
        setValue(`${key}`, $payload, { pageId, viewName, reEvaluateErrorsAndDirty: false });
      }
    }
  });

  return result;
};

function concat(...args) {
  return args.join("");
}

function urlEncode(input) {
  return encodeURIComponent(input);
}

function contrast(hexColor1: string, hexColor2: string): number {
  const Color = require("color");
  const backgroundColor = Color(hexColor1);
  const fontColor = Color(hexColor2);

  const contrastRatio = backgroundColor.contrast(fontColor);
  return contrastRatio;
}

function buildLinks(texts: string[], urls: string[], separator: string = ""): string {
  const anchors = texts.map((text, index) => {
    if (!text) return "";
    return urls?.[index] ? `<a href="${urls[index]}">${text}</a>` : text;
  });
  return anchors.filter(Boolean).join(separator);
}

function join(array, delimiter) {
  return array.join(delimiter);
}

function evalWithArgs(...params: any[]): any {
  const expression = params[0];
  let args = params.slice(1);

  if (args.some(arg => arg === undefined || arg === null)) {
    return "";
  }
  if (typeof expression !== "string") return expression;

  const code = `const args = ${JSON.stringify(args)};\n${expression}`;

  try {
    const result = eval(code);
    if (typeof result === "number" && Number.isNaN(result)) {
      return "";
    }
    return result;
  } catch (error) {
    return "";
  }
}

function toObjArray(arr: any[], key: string): Record<string, any>[] {
  return arr.map(item => ({ [key]: item }));
}

export function evaluateExpression(expression) {
  if (typeof expression !== "string") return expression;

  const operations = {
    "=concat": concat,
    "=urlEncode": urlEncode,
    "=contrast": contrast,
    "=buildLinks": buildLinks,
    "=join": join,
    "=toObjArray": toObjArray,
    "=eval": eval,
    "=evalWithArgs": evalWithArgs,
  };

  const evalExpr = expr => {
    for (const [prefix, func] of Object.entries(operations)) {
      if (expr.startsWith(prefix)) {
        return eval(expr.replace(prefix, func.name));
      }
    }
    return expr;
  };

  expression = expression.replace(/\{=(.*?)\}/g, (_, exprContent) => {
    try {
      return evalExpr(`=${exprContent}`);
    } catch {
      return "";
    }
  });

  if (expression.startsWith("=")) {
    try {
      return evalExpr(expression);
    } catch {
      return "";
    }
  }

  return expression;
}

export const replaceDataPlaceholders = (props: any) => {
  const {
    queryString,
    item = {},
    viewsState,
    watch,
    watchError,
    getDirty,
    getValue,
    setValue,
    formData,
    __data = {},
    fallback,
    actionResponse,
    dnd,
    env,
    pagination,
    multiLingual,
    validationRules,
    index: itemIndex,
    actionIndex,
    queriesStateGraphQL,
    viewName,
    location,
    selector,
    keyComp,
    fromParent,
    dataEntry,
    currentBreakpoint,
    actions,
    parentDataIndex,
    appId,
    tableAction,
    _key,
    canWatch,
    state,
    pageIdFromLayout,
    ...rest
  } = props;

  let { pageId } = rest;

  let result = queryString;

  let customLocalStorage: any = {};
  for (let i = 0; i < localStorage.length; i++) {
    let key: any = localStorage.key(i);
    let value = localStorage.getItem(key);
    if (key === `${appId}-accessToken-device`) {
      key = "deviceAccessToken";
    }

    try {
      customLocalStorage[key] = JSON.parse(value!);
    } catch {
      customLocalStorage[key] = value;
    }
  }
  const buildx = { appVersion: appVersion };
  const global = {
    location,
    env: {
      ...env?.config?.variables,
      ...env?.upConfig?.variables,
    },
    browser: {
      deviceType: config.deviceType,
      osVersion: osVersion,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    localStorage: customLocalStorage,
    breakpoint: currentBreakpoint,
  };

  const mapObj = {
    data: item,
    pagination,
    actionResponse,
    dnd,
    actions,
    ...rest,
  };

  if (typeof queryString === "boolean" || typeof queryString === "number" || typeof queryString === "object" || queryString == undefined)
    return queryString;

  if (queryString.includes("{$.")) {
    result = result.replaceAll("$.", `${selector}.`);
  }

  if (queryString.includes("{@.")) {
    result = result.replace(/\@/g, "this.actions[*].rows[*]");
  }

  if (queryString?.endsWith(".response}")) {
    result = result.replace(".response}", ".response._body}");
  }

  if (queryString.includes("{_currentIndex}")) {
    const baseIndex = isNumber(parentDataIndex) ? parentDataIndex : itemIndex;
    result = result.replaceAll("{_currentIndex}", baseIndex);
  }

  const resolvePlaceholders = (str: any): any => {
    const placeholderRegex = /\{\{(?!\=)([^{}]+?)\}\}|\{(?!\=)([^{}]+?)\}/g;
    let match;
    const processedPlaceholders = new Map();
    const memoizedResolved = new Map();
    let finalResult = str;

    while ((match = placeholderRegex.exec(str)) !== null) {
      let placeholder = match[0]; // {this}

      if (memoizedResolved.has(placeholder)) {
        const replacedValue = memoizedResolved.get(placeholder);
        str = str.replace(placeholder, replacedValue);
        finalResult = finalResult.replace(placeholder, replacedValue);
        placeholderRegex.lastIndex = 0;
        if (processedPlaceholders.get(placeholder) === str) {
          return finalResult;
        }
        continue;
      }

      let phText = "";
      let data;

      const index = placeholder.split("").findIndex((key: string) => key == ".");
      phText = placeholder.substring(index + 1, placeholder.length - 1);

      data = mapObj;
      if (placeholder.startsWith("{i18n.")) {
        const translationKey = phText;
        const translationObject = multiLingual?.translations?.find((t: any) => t.key === translationKey);

        const defaultLanguage = multiLingual?.selectedLanguage;
        const selectedLanguage = defaultLanguage ? defaultLanguage : "";

        data = translationObject?.allLanguages;
        phText = selectedLanguage;
      } else if (placeholder.startsWith("{{")) {
        phText = placeholder.substring(2, placeholder.length - 2);
        data = global.env;
        if (!_.has(data, phText)) {
          str = str.replace(placeholder, `{${phText}}`);
          finalResult = finalResult.replace(placeholder, `{${phText}}`);
          placeholderRegex.lastIndex = 0;
          continue;
        }
      } else if (placeholder.startsWith("{buildxLogin")) {
        data = data.login;
      } else if (placeholder.startsWith("{$global")) {
        data = global;

        phText = phText.replace("{$global.", "");
        if (placeholder.includes(".")) {
          phText = placeholder.substring(index + 1, placeholder.length - 1);
        } else {
          phText = "";
        }
      } else if (placeholder.startsWith("{$buildx")) {
        data = buildx;

        phText = phText.replace("{$buildx.", "");
        if (placeholder.includes(".")) {
          phText = placeholder.substring(index + 1, placeholder.length - 1);
        } else {
          phText = "";
        }
      } else if (placeholder.startsWith("{this.actionResponse")) {
        data = actionResponse;
        let newText = placeholder.replace("{this.actionResponse.", "");
        phText = newText.replace("}", "");
      } else {
        let selectedViewName = viewName;

        if (placeholder.startsWith("{#.")) {
          const hashes = Object.keys(__data)?.reverse();
          phText = placeholder.substring(1, placeholder.length - 1);
          if (phText.includes("*")) {
            const baseIndex = isNumber(parentDataIndex) ? parentDataIndex : itemIndex;
            phText = phText.replace("*", baseIndex);
          }

          const currentHash = hashes?.find(hash => phText.startsWith(hash)) as any;

          if (!currentHash) return;

          selectedViewName = reverseDataOfObjectValues(__data)[currentHash];

          phText = phText.replace(currentHash, "");
          data = getValue(`${pageId}.${selectedViewName}.selectedItem.${phText}`, { pageId, viewName: selectedViewName });

          if (typeof data === "object") {
            if (str === placeholder) {
              str = data;
              finalResult = data;
            } else {
              str = str.replace(placeholder, "");
              finalResult = finalResult.replace(placeholder, JSON.stringify(data));
            }
          } else {
            str = str.replace(placeholder, data || fallback);
            finalResult = finalResult.replace(placeholder, data || fallback);
          }
          placeholderRegex.lastIndex = 0;
          continue;
        } else if (!placeholder.startsWith("{this")) {
          selectedViewName = placeholder.substring(1, placeholder.includes(".") ? index : placeholder.length - 1);
        }
        if (queryString.includes(".response._status")) {
          result = getValue(`${pageId}.${viewName}.${_key}.response._status`, { pageId, viewName });
        }

        if (placeholder.includes(".")) {
          phText = placeholder.substring(index + 1, placeholder.length - 1);
        } else {
          phText = "";
        }

        phText = phText.replace(/\[\*\s*([+-]?\d+)?\]/g, function (match, number, offset) {
          const isActionIndex = phText.substring(0, offset).endsWith("actions");
          const isDataIndex = phText.substring(0, offset).endsWith("data._body"); //This is to handle some nested repeated items cases
          let baseIndex = itemIndex;
          if (isDataIndex) {
            baseIndex = isNumber(parentDataIndex) ? parentDataIndex : itemIndex;
          }

          if (isActionIndex) {
            baseIndex = actionIndex;
          }

          let indexAdjustment = number ? parseInt(number, 10) : 0;
          let adjustedIndex = baseIndex + indexAdjustment;

          return `[${adjustedIndex}]`;
        });

        //Handle access from parent for both data entry and data directly (e.g: {this.data})/
        if (fromParent && dataEntry && phText !== "data") {
          const keyPath = phText.replace("data.", "");

          const dynamicBase = placeholder.split(".")[1];
          phText = `${dynamicBase}.${dataEntry}[${itemIndex}].${keyPath}`;
        }
        let pathName = `${pageId}`;

        if (selectedViewName) {
          pathName = `${pathName}.${selectedViewName}`;
        }
        if (phText) {
          pathName = `${pathName}.${phText}`;
        }

        if (placeholder.startsWith("{$comp")) {
          pathName = `${pageId}.${viewName}.${keyComp}`;
          if (phText) {
            pathName = `${pathName}.${phText}`;
          }
        }

        if (placeholder.startsWith("{$page")) {
          pageId = pageId ? pageId : pageIdFromLayout;
          pathName = `${pageId}`;
          if (phText) {
            pathName = `${pathName}.${phText}`;
          }
        }

        if (placeholder.startsWith("{$app")) {
          pathName = `application`;
          if (phText) {
            pathName = `${pathName}.${phText}`;
          }
        }

        const pathProperty = extractPropertyForSyntax(pathName);
        if (pathProperty?.prefixPath && !BXPredefinedSyntaxProperties.has(pathProperty.property)) {
          const pathParts = pathName.split(".");
          pathParts[pathParts.length - 1] = "_body";
          const newPath = pathParts.join(".");
          const dataWithBody = getValue?.(newPath, { pageId, viewName });
          const flagWithIndex = Array.isArray(dataWithBody) ? true : false;

          if (
            (!isNumber(itemIndex) && !isNumber(parentDataIndex)) ||
            fromParent ||
            !_.isEmpty(tableAction?.action) ||
            selector ||
            !flagWithIndex
          ) {
            pathName = pathName.replace(
              `${pathProperty.prefixPath}.${pathProperty.property}`,
              `${pathProperty.prefixPath}._body.${pathProperty.property}`
            );
          } else {
            const correctIndex = isNumber(parentDataIndex) ? parentDataIndex : itemIndex;
            pathName = pathName.replace(
              `${pathProperty.prefixPath}.${pathProperty.property}`,
              `${pathProperty.prefixPath}._body[${correctIndex}].${pathProperty.property}`
            );
          }
        }

        if (pathName.match(/^[^.]+\.[^.]+\.[^.]+\.state\[\d+\]\.[^.]+$/)) {
          pathName += ".state";
        }
        data = getValue?.(pathName, { pageId, viewName, state });

        if (data?.match?.(placeholderRegex)) {
          data = resolvePlaceholders(data);
        }
        phText = "";
      }

      phText = phText.replace(/\[\*\s*([+-]?\d+)?\]/g, function (match, number, offset) {
        let indexAdjustment = number ? parseInt(number, 10) : 0;
        let adjustedIndex = itemIndex + indexAdjustment;

        return `[${adjustedIndex}]`;
      });

      let replacedValue: any = null;
      if (phText.includes("[]")) {
        const prefix = phText.substring(0, phText.indexOf("[]"));
        const suffix = phText.substring(phText.indexOf("[].") + 3, phText.length);

        if (_.isArray(_.get(data, prefix))) {
          replacedValue = _.get(data, prefix)?.map(item => _.get(item, suffix));
        }
      } else {
        if (!_.isNil(data) && !phText) {
          replacedValue = data;
        } else {
          let pathParts = phText.split(".");
          let lastPart = pathParts.pop();
          let parentPath = pathParts.join(".");

          let parentData = _.get(data, parentPath);

          if (_.isArray(parentData)) {
            replacedValue = _.map(parentData, lastPart);
          } else {
            replacedValue = !_.isNil(_.get(data, phText)) ? _.get(data, phText) : fallback;
            if (!replacedValue) {
              replacedValue = !_.isNil(_.get(data, `entities.${phText}`)) ? _.get(data, `entities.${phText}`) : fallback;
            }
          }
        }
      }

      //Resolve responsive values
      if (placeholder.includes(".props.") || placeholder.includes(".config.")) {
        replacedValue = extractResponsiveProperty(replacedValue, currentBreakpoint);
      }

      if (_.isNil(replacedValue)) {
        finalResult = str === placeholder ? replacedValue : finalResult.replace(placeholder, replacedValue);
        str = str === placeholder ? replacedValue : str.replace(placeholder, replacedValue);
      } else if (typeof replacedValue === "object") {
        if (str === placeholder) {
          str = replacedValue;
          finalResult = replacedValue;
        } else {
          str = str.replace(placeholder, "");
          finalResult = finalResult.replace(placeholder, JSON.stringify(replacedValue));
        }
      } else {
        str = str.replace(placeholder, replacedValue);
        finalResult = finalResult.replace(placeholder, replacedValue);
      }

      if (processedPlaceholders.get(placeholder) === str) {
        return finalResult;
      } else {
        processedPlaceholders.set(placeholder, str);
      }
      memoizedResolved.set(placeholder, replacedValue);

      placeholderRegex.lastIndex = 0;
    }
    return finalResult;
  };
  result = resolvePlaceholders(result);
  return evaluateExpression(result);
};
export const replaceDataPlaceholdersPathName = (props: any) => {
  const {
    queryString,
    item = {},
    viewsState,
    watch,
    watchError,
    getDirty,
    setValue,
    pageId,
    formData,
    __data = {},
    fallback,
    actionResponse,
    dnd,
    env,
    pagination,
    multiLingual,
    validationRules,
    index: itemIndex,
    actionIndex,
    queriesStateGraphQL,
    viewName,
    location,
    selector,
    keyComp,
    fromParent,
    dataEntry,
    currentBreakpoint,
    actions,
    parentDataIndex,
    appId,
    tableAction,
    _key,
    canWatch,
    state,
    getValue,
    ...rest
  } = props;

  let result = queryString;

  let customLocalStorage: any = {};
  for (let i = 0; i < localStorage.length; i++) {
    let key: any = localStorage.key(i);
    let value = localStorage.getItem(key);
    if (key === `${appId}-accessToken-device`) {
      key = "deviceAccessToken";
    }

    try {
      customLocalStorage[key] = JSON.parse(value!);
    } catch {
      customLocalStorage[key] = value;
    }
  }
  const buildx = { appVersion: appVersion };
  const global = {
    location,
    env: {
      ...env?.config?.variables,
      ...env?.upConfig?.variables,
    },
    browser: {
      deviceType: config.deviceType,
      osVersion: osVersion,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    localStorage: customLocalStorage,
    breakpoint: currentBreakpoint,
  };

  const mapObj = {
    data: item,
    pagination,
    actionResponse,
    dnd,
    actions,
    ...rest,
  };

  if (typeof queryString === "boolean" || typeof queryString === "number" || typeof queryString === "object" || queryString == undefined)
    return queryString;

  if (queryString.includes("{$.")) {
    result = result.replaceAll("$.", `${selector}.`);
  }

  if (queryString.includes("{@.")) {
    result = result.replace(/\@/g, "this.actions[*].rows[*]");
  }

  if (queryString?.endsWith(".response}")) {
    result = result.replace(".response}", ".response._body}");
  }

  if (queryString.includes("{_currentIndex}")) {
    const baseIndex = isNumber(parentDataIndex) ? parentDataIndex : itemIndex;
    result = result.replaceAll("{_currentIndex}", baseIndex);
  }

  const nestedResolved = new Map();

  const resolvePlaceholders = (str: any): any => {
    const placeholderRegex = /\{\{(?!\=)([^{}]+?)\}\}|\{(?!\=)([^{}]+?)\}/g;
    const placeholderRegexTest = /\{\{(?!\=)([^{}]+?)\}\}|\{(?!\=)([^{}]+?)\}/g;
    const nestedRegex = /^\{[^{}]*\{[^{}]+\}[^{}]*\}$/;

    let match;
    const processedPlaceholders = new Map();
    const memoizedResolved = new Map();
    let finalResult = str;

    while ((match = placeholderRegex.exec(str)) !== null) {
      let placeholder = match[0]; // {this}
      let placeholderToReplace = match[2]; // this

      let replacedValue;
      if (memoizedResolved.has(placeholder)) {
        const replacedValue = memoizedResolved.get(placeholder);
        str = str.replace(placeholder, replacedValue);
        finalResult = finalResult.replace(placeholder, replacedValue);
        placeholderRegex.lastIndex = 0;
        if (processedPlaceholders.get(placeholder) === str) {
          return finalResult;
        }
        continue;
      }

      let phText = "";
      let data;

      const index = placeholder.split("").findIndex((key: string) => key == ".");
      phText = placeholder.substring(index + 1, placeholder.length - 1);

      data = mapObj;
      if (placeholder.startsWith("{i18n.")) {
        const translationKey = phText;
        const translationObject = multiLingual?.translations?.find((t: any) => t.key === translationKey);

        const defaultLanguage = multiLingual?.selectedLanguage;
        const selectedLanguage = defaultLanguage ? defaultLanguage : "";

        data = translationObject?.allLanguages;
        replacedValue = translationObject?.allLanguages;
        phText = selectedLanguage;
        finalResult = finalResult.replace(placeholder, replacedValue);
      } else if (placeholder.startsWith("{{")) {
        phText = placeholder.substring(2, placeholder.length - 2);
        data = global.env;
        // Get the specific property value
        replacedValue = _.get(data, phText, placeholder);
        finalResult = finalResult.replace(placeholder, replacedValue);

        if (!_.has(data, phText)) {
          str = str.replace(placeholder, `{${phText}}`);
          finalResult = finalResult.replace(placeholder, `{${phText}}`);
          placeholderRegex.lastIndex = 0;
          continue;
        }
      } else if (placeholder.startsWith("{buildxLogin")) {
        data = data.login;
        replacedValue = data.login;
        finalResult = finalResult.replace(placeholder, replacedValue);
      } else if (placeholder.startsWith("{$global")) {
        data = global;
        replacedValue = global;

        phText = phText.replace("{$global.", "");
        if (placeholder.includes(".")) {
          phText = placeholder.substring(index + 1, placeholder.length - 1);
          replacedValue = _.get(global, phText);
        } else {
          phText = "";
        }
        finalResult = finalResult.replace(placeholder, replacedValue);
      } else if (placeholder.startsWith("{$buildx")) {
        data = buildx;
        replacedValue = buildx;

        phText = phText.replace("{$buildx.", "");
        if (placeholder.includes(".")) {
          phText = placeholder.substring(index + 1, placeholder.length - 1);
          replacedValue = _.get(buildx, phText);
        } else {
          phText = "";
        }
        finalResult = finalResult.replace(placeholder, replacedValue);
      } else if (placeholder.startsWith("{this.actionResponse")) {
        data = actionResponse;
        replacedValue = actionResponse;
        let newText = placeholder.replace("{this.actionResponse.", "");
        phText = newText.replace("}", "");
        if (phText) {
          replacedValue = _.get(actionResponse, phText);
        }
        finalResult = finalResult.replace(placeholder, replacedValue);
      } else {
        let selectedViewName = viewName;

        if (placeholder.startsWith("{#.")) {
          const hashes = Object.keys(__data)?.reverse();
          phText = placeholder.substring(1, placeholder.length - 1);
          if (phText.includes("*")) {
            const baseIndex = isNumber(parentDataIndex) ? parentDataIndex : itemIndex;
            phText = phText.replace("*", baseIndex);
          }
          const currentHash = hashes?.find(hash => phText.startsWith(hash)) as any;

          if (!currentHash) return;

          selectedViewName = reverseDataOfObjectValues(__data)[currentHash];

          phText = phText.replace(currentHash, "");
          replacedValue = `${pageId}.${selectedViewName}.selectedItem.${phText}`;
        } else if (!placeholder.startsWith("{this")) {
          selectedViewName = placeholder.substring(1, placeholder.includes(".") ? index : placeholder.length - 1);
        }
        if (queryString.includes(".response._status")) {
          result = getValue(`${pageId}.${viewName}.${_key}.response._status`, { pageId, viewName });
        }

        if (placeholder.includes(".")) {
          phText = placeholder.substring(index + 1, placeholder.length - 1);
        } else {
          phText = "";
        }

        phText = phText.replace(/\[\*\s*([+-]?\d+)?\]/g, function (match, number, offset) {
          const isActionIndex = phText.substring(0, offset).endsWith("actions");
          const isDataIndex = phText.substring(0, offset).endsWith("data._body"); //This is to handle some nested repeated items cases
          let baseIndex = itemIndex;
          if (isDataIndex) {
            baseIndex = isNumber(parentDataIndex) ? parentDataIndex : itemIndex;
          }

          if (isActionIndex) {
            baseIndex = actionIndex;
          }

          let indexAdjustment = number ? parseInt(number, 10) : 0;
          let adjustedIndex = baseIndex + indexAdjustment;

          return `[${adjustedIndex}]`;
        });

        //Handle access from parent for both data entry and data directly (e.g: {this.data})/
        if (fromParent && dataEntry && phText !== "data") {
          const keyPath = phText.replace("data.", "");

          const dynamicBase = placeholder.split(".")[1];
          phText = `${dynamicBase}.${dataEntry}[${itemIndex}].${keyPath}`;
        }
        let pathName = `${pageId}`;

        if (selectedViewName) {
          pathName = `${pathName}.${selectedViewName}`;
        }
        if (phText) {
          pathName = `${pathName}.${phText}`;
        }

        if (placeholder.startsWith("{$comp")) {
          pathName = `${pageId}.${viewName}.${keyComp}`;
          if (phText) {
            pathName = `${pathName}.${phText}`;
          }
        }

        if (placeholder.startsWith("{$page")) {
          pathName = `${pageId}`;
          if (phText) {
            pathName = `${pathName}.${phText}`;
          }
        }

        if (placeholder.startsWith("{$app")) {
          pathName = `application`;
          if (phText) {
            pathName = `${pathName}.${phText}`;
          }
        }

        const pathProperty = extractPropertyForSyntax(pathName);
        if (pathProperty?.prefixPath && !BXPredefinedSyntaxProperties.has(pathProperty.property)) {
          const pathParts = pathName.split(".");
          pathParts[pathParts.length - 1] = "_body";
          const newPath = pathParts.join(".");
          const dataWithBody = getValue?.(newPath, { pageId, viewName });
          const flagWithIndex = Array.isArray(dataWithBody) ? true : false;

          if (
            (!isNumber(itemIndex) && !isNumber(parentDataIndex)) ||
            fromParent ||
            !_.isEmpty(tableAction?.action) ||
            selector ||
            !flagWithIndex
          ) {
            pathName = pathName.replace(
              `${pathProperty.prefixPath}.${pathProperty.property}`,
              `${pathProperty.prefixPath}._body.${pathProperty.property}`
            );
          } else {
            const correctIndex = isNumber(parentDataIndex) ? parentDataIndex : itemIndex;
            pathName = pathName.replace(
              `${pathProperty.prefixPath}.${pathProperty.property}`,
              `${pathProperty.prefixPath}._body[${correctIndex}].${pathProperty.property}`
            );
          }
        }

        if (pathName.match(/^[^.]+\.[^.]+\.[^.]+\.state\[\d+\]\.[^.]+$/)) {
          pathName += ".state";
        }

        replacedValue = replacedValue ? replacedValue : pathName;

        if (getValue) {
          data = getValue(pathName, { pageId, viewName });
        }

        // if (data?.match?.(placeholderRegex)) {
        //   replacedValue = resolvePlaceholders(data);
        // }
        phText = "";
      }

      if (_.isNil(replacedValue)) {
        finalResult = str === placeholder ? replacedValue : finalResult.replace(placeholderToReplace, replacedValue);
        str = str === placeholder ? replacedValue : str.replace(placeholder, replacedValue);
      } else if (typeof replacedValue === "object") {
        if (str === placeholder) {
          str = replacedValue;
          finalResult = replacedValue;
        } else {
          str = str.replace(placeholder, "");
          finalResult = finalResult.replace(placeholderToReplace, JSON.stringify(replacedValue));
        }
      } else {
        let _correctReplaceValue;
        const testData = placeholderRegexTest.test(data);
        const testNested = nestedRegex.test(queryString);
        if (testData) {
          _correctReplaceValue = data;
          str = str.replace(placeholder, _correctReplaceValue);
          finalResult = finalResult.replace(placeholderToReplace, _correctReplaceValue);
        } else if (testNested) {
          _correctReplaceValue = data;
          _correctReplaceValue = replacedValue;
          if (str.includes(`${placeholder}.`)) {
            str = str.replace(`${placeholder}.`, "");
          } else {
            str = str.replace(placeholder, "");
          }
          finalResult = finalResult.replace(placeholderToReplace, _correctReplaceValue);
          nestedResolved.set(placeholderToReplace, replacedValue);
        } else {
          _correctReplaceValue = replacedValue;
          str = str.replace(placeholder, _correctReplaceValue);
          finalResult = finalResult.replace(placeholderToReplace, _correctReplaceValue);
        }
      }

      placeholderRegexTest.lastIndex = 0;
      nestedRegex.lastIndex = 0;
      placeholderRegex.lastIndex = 0;
    }
    return finalResult;
  };

  result = resolvePlaceholders(result);
  if (nestedResolved.size > 0) {
    const forbiddenSubstrings = ["=concat", "=urlEncode", "=contrast", "=buildLinks", "=join", "=toObjArray", "=eval", "=evalWithArgs"];
    if (!forbiddenSubstrings.some(sub => result.includes(sub))) {
      result = result.replace(/([^\.])\{/g, "$1.{");
    }
    if (result.includes("this")) {
      result = result.replaceAll("this", `${pageId}.${viewName}`);
    }
  }
  return result;
};

export const useReplaceDataPlaceholdersUseSelector = (props: any) => {
  const {
    queryString,
    item = {},
    viewsState,
    watch,
    watchError,
    getDirty,
    getValue,
    getValueRecursively,
    setValue,
    pageId,
    formData,
    useSelector,
    __data = {},
    fallback,
    actionResponse,
    dnd,
    env,
    pagination,
    multiLingual,
    validationRules,
    index,
    actionIndex,
    queriesStateGraphQL,
    viewName,
    location,
    selector,
    keyComp,
    fromParent,
    dataEntry,
    currentBreakpoint,
    actions,
    parentDataIndex,
    appId,
    tableAction,
    _key,
    canWatch,
    ...rest
  } = props;

  const queryStringWithPathName = useMemo(() => {
    return replaceDataPlaceholdersPathName({
      queryString,
      item,
      viewsState,
      watch,
      watchError,
      getDirty,
      getValue,
      getValueRecursively,
      setValue,
      pageId,
      formData,
      useSelector,
      __data,
      fallback,
      actionResponse,
      dnd,
      env,
      pagination,
      multiLingual,
      validationRules,
      index,
      actionIndex,
      queriesStateGraphQL,
      viewName,
      location,
      selector,
      keyComp,
      fromParent,
      dataEntry,
      currentBreakpoint,
      actions,
      parentDataIndex,
      appId,
      tableAction,
      _key,
      canWatch,
      ...rest,
    });
  }, [queryString, pageId, viewName, currentBreakpoint]);

  const finalValue = useSelector(() => {
    return getValueRecursively(queryStringWithPathName, { pageId, viewName, fallback });
  }, _.isEqual);

  return finalValue || fallback;
};

export const replaceDataPlaceholdersRecursively = ({
  item,
  viewsState,
  pageId,
  formData,
  __data,
  multiLingual,
  env,
  keyComp,
  obj = {},
  actions,
  appId,
  pageIdFromLayout,
  ...rest
}: any) => {
  Object.keys?.(obj ?? {})?.forEach?.(key => {
    let template = obj?.[key];

    if (typeof obj[key] === "object" && obj[key] !== null) {
      return replaceDataPlaceholdersRecursively({
        obj: obj[key],
        item,
        viewsState,
        pageId,
        env,
        formData,
        multiLingual,
        __data,
        actions,
        appId,
        pageIdFromLayout,
        ...rest,
      });
    }
    obj[key] = replaceDataPlaceholders({
      queryString: template,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      multiLingual,
      env,
      keyComp,
      actions,
      appId,
      pageIdFromLayout,
      ...rest,
    });
  });

  return obj;
};

export const useReplaceDataPlaceholdersRecursively = ({
  item,
  viewsState,
  pageId,
  formData,
  __data,
  multiLingual,
  env,
  keyComp,
  obj = {},
  actions,
  appId,
  viewName,
  getValue,
  fallback,
  getValueRecursively,
  index,
  parentDataIndex,
  ...rest
}: any) => {
  const resolvePlaceholders = (currentObj: any): any => {
    if (Array.isArray(currentObj)) {
      return currentObj.map(element => {
        if (typeof element === "object" && element !== null) {
          return resolvePlaceholders(element);
        } else if (element !== null && typeof element !== "object") {
          return replaceDataPlaceholdersPathName({
            queryString: element,
            item,
            viewsState,
            pageId,
            formData,
            __data,
            multiLingual,
            env,
            keyComp,
            actions,
            appId,
            viewName,
            getValue,
            index,
            parentDataIndex,
            ...rest,
          });
        }
        return element;
      });
    } else if (typeof currentObj === "object" && currentObj !== null) {
      const newObj: any = {};
      Object.keys(currentObj).forEach(key => {
        const template = currentObj[key];
        if (typeof template === "object" && template !== null) {
          newObj[key] = resolvePlaceholders(template);
        } else if (template !== null && typeof template !== "object") {
          newObj[key] = replaceDataPlaceholdersPathName({
            queryString: template,
            item,
            viewsState,
            pageId,
            formData,
            __data,
            multiLingual,
            env,
            keyComp,
            actions,
            appId,
            viewName,
            index,
            parentDataIndex,
            getValue,
            ...rest,
          });
        }
      });
      return newObj;
    }
    return currentObj;
  };
  const resolvePlaceholdersWithGetValue = (currentObj: any, state, fallback): any => {
    if (Array.isArray(currentObj)) {
      return currentObj.map(element => {
        if (typeof element === "object" && element !== null) {
          return resolvePlaceholdersWithGetValue(element, state, fallback);
        } else if (element !== null && element !== undefined && typeof element !== "object") {
          return getValueRecursively(element, { pageId, viewName, fallback });
        }
        return element;
      });
    } else if (typeof currentObj === "object" && currentObj !== null) {
      const newObj: any = {};
      Object.keys(currentObj).forEach(key => {
        const template = currentObj[key];
        if (typeof template === "object" && template !== null) {
          newObj[key] = resolvePlaceholdersWithGetValue(template, state, fallback);
        } else if (template !== null && template !== undefined && typeof template !== "object") {
          newObj[key] = getValueRecursively(template, { pageId, viewName, fallback });
        }
      });
      return newObj;
    }
    return currentObj;
  };

  // const objWithPathName = useMemo(() => {
  //   return resolvePlaceholders(obj);
  // }, [JSON.stringify(obj)]);
  const objWithPathName = useMemo(() => {
    return resolvePlaceholders(obj);
  }, [JSON.stringify(obj)]);

  const replacedResult = useSelector((state: RootState) => {
    const resolvedObj = resolvePlaceholdersWithGetValue(objWithPathName, state, fallback);
    return resolvedObj;
  }, _.isEqual);

  return replacedResult;
};

export const formatJSON = (val = {}) => {
  try {
    const res = JSON.parse(val as any);
    return JSON.stringify(res, null, 2);
  } catch {
    return undefined;
  }
};

//Actionable Components Renderer
export const ActionButton: FC<{
  actionsMap?: any;
  action?: any;
  pageId?: any;
  onStateChange?: (newState: string) => void;
  disabled?: boolean;
  item: any;
  isUserInput?: boolean;
  views?: UIElement[];
  onSelectRow?: any;
  tableId: any;
  handleSubmit?: any;
  iconButton?: boolean;
  formData?: any;
  fullWidth?: boolean;
  variant?: string;
  __data?: any;
  closeModal?: any;
  parentIds?: any;
  isDismissibleView?: string;
  queryKeys?: any[];
  withBorder?: boolean;
  isResetEnabled?: boolean;
  viewName?: string;
  entries?: any;
  path?: string;
  selectedRows?: any;
  _key?: any;
  components?: any;
  index?: any;
  isChainMapped?: any;
  actionsKey?: any;
  interactionConfig?: any;
  tableAction?: any;
  isLoadingForEntireChain?: boolean;
  element?: any;
  stripe?: any;
  elements?: any;
  conditionKeyFromTable?: any;
  currentRepeatedItem?: any;
  dataEntry?: any;
  fromParent?: boolean;
  viewNameOption?: any;
  actionIndex?: number;
  actions?: any;
  pageIdFromLayout?: any;
  fieldAlignment?: "left" | "right" | "center";
  isComponentDisabled?: any;
  componentVisibility?: any;
  collectionId?: any;
  sharedPageId?: any;
}> = props => {
  const {
    item,
    isUserInput,
    views,
    onSelectRow,
    pageId,
    tableId,
    formData,
    parentIds,
    iconButton = true,
    fullWidth = false,
    variant = "contained",
    __data = {},
    withBorder = true,
    queryKeys = [],
    viewName,
    selectedRows,
    path,
    children,
    _key,
    index,
    tableAction,
    element,
    actionIndex,
    actions,
    pageIdFromLayout,
    disabled = false,
    fieldAlignment = "left",
    collectionId,
    sharedPageId,
  } = props;

  const actionId = tableAction?.action ? _key : element?.id;

  const { palette } = useTheme();

  const {
    executeChain,
    isViewActionTriggered,
    jsonProgress,
    isLoading,
    setIsViewActionTriggered,
    setActionView,
    setSelectedView,
    proceedToNextAction,
    actionModal,
    currentApp,
    viewsState,
    setShowModalForAction,
    setActionModal,
    showModalForAction,
    actionConfirmControl,
    handleCancelAction,
    handleConfirmAction,
    open,
    handleClose,
    isConfirmAction,
    actionView,
    selectedView,
    onAction,
    onClickHandler,
  } = useActionHandler(props);

  const { replaceDataPlaceholders } = useReplaceDataPlaceholders({
    viewName,
    pageId,
    item,
    viewsState,
  });

  const renderActionableComponent = () => {
    return children ? (
      <span style={{ display: "contents" }} onClick={onClickHandler}>
        {children}
      </span>
    ) : (
      <Tooltip title={tableAction?.label}>
        {iconButton ? (
          <TableAction
            tableAction={tableAction}
            onSelectRow={onSelectRow}
            executeChain={executeChain}
            switchValue={replaceDataPlaceholders({
              queryString: tableAction?.action?.source,
              item,
              viewsState,
              pageId,
              viewName,
              __data,
              env: currentApp?.env,
              index,
              fallback: "",
              selector: tableAction?.dataEntry ? `this.data.${tableAction?.dataEntry}[*]` : `this.data._body[*]`,
            })}
            disabled={disabled}
            isLoading={isLoading}
            item={item}
          />
        ) : (
          <LoadingButton
            loading={isLoading}
            loadingPosition='start'
            aria-label={tableAction?.label}
            variant={variant as any}
            disabled={disabled}
            onClick={e => {
              onSelectRow?.(item);
              e.stopPropagation();
              executeChain(e);
            }}
            startIcon={tableAction?.icon && <BXIcon icon={tableAction?.icon} width={20} height={20} color={palette.text.primary} />}
            fullWidth={fullWidth}
            style={
              !withBorder
                ? {
                    borderWidth: 0,
                    justifyContent: fieldAlignment === "left" ? "flex-start" : fieldAlignment === "right" ? "flex-end" : "center",
                  }
                : {}
            }
          >
            <Typography color={palette.text.primary}>{tableAction?.label}</Typography>
          </LoadingButton>
        )}
      </Tooltip>
    );
  };

  const isAPI = false;

  return (
    <>
      {isAPI && <CSVProgressDialog open={open} handleClose={handleClose} jsonData={jsonProgress} />}

      {isConfirmAction === actionId && (
        <BXConfirmationDialog
          views={views}
          open={isConfirmAction === actionId}
          title={actionModal?.title || "Are you sure?"}
          iconButton
          onConfirm={async () => {
            await handleConfirmAction();
          }}
          onCancel={() => {
            handleCancelAction();
          }}
          isCustom={actionConfirmControl?.confirmationDialogData?.customViewEnabled}
          isCustomWidth={actionConfirmControl?.currentAction?.isCustomWidthConfirm}
          customModalWidth={actionConfirmControl?.currentAction?.customModalWidthConfirm}
          customDialogView={actionConfirmControl?.confirmationDialogData?.customView}
          customDialogData={actionConfirmControl?.confirmationDialogData}
          pageId={pageId}
        />
      )}

      {actionModal && (
        <BXModal
          open={showModalForAction}
          buttonComponent={children}
          label={actionModal?.label}
          icon={<BXIcon icon={actionModal?.icon} width={14} height={14} color={"currentColor"} />}
          buttonProps={{
            variant: "contained",
            startIcon: <BXIcon icon={actionModal?.icon} width={14} height={14} color={"currentColor"} />,
          }}
          title={actionModal?.label}
          withoutLabel={iconButton}
          onClose={() => {
            setShowModalForAction(false);
            setActionModal(null);
            proceedToNextAction?.cancel();
          }}
        >
          {(handleClose: Function) => {
            const isGraphQL = actionModal?.isGraphQL || false;

            const actionBody = isGraphQL ? actionModal?.graphqlVariables : actionModal?.body;

            const resolvedBody = replaceDataPlaceholdersRecursively({
              obj: JSON.parse(actionModal?.showModal === "Yes" ? actionBody : actionBody || "{}"),
              viewsState,
              pageId,
              item,
              formData,
              __data,
              env: currentApp?.env,
              selectedRows,
              index,
            });

            const resolvedHeaders = replaceDataPlaceholdersRecursively({
              obj: actionModal?.headers,
              viewsState,
              pageId,
              item,
              formData,
              __data,
              env: currentApp?.env,
              selectedRows,
              index,
            });

            const uri = replaceDataPlaceholders({
              queryString: actionModal?.source,
              item,
              viewsState,
              formData,
              pageId,
              __data,
              index,
              actionIndex,
              actions,
            });
            return (
              <CreatePayload
                payload={{
                  ...(isGraphQL
                    ? { graphqlVariables: JSON.stringify(resolvedBody), graphqlQuery: actionModal?.graphqlQuery }
                    : { body: JSON.stringify(resolvedBody) }),
                  headers: resolvedHeaders,
                  uri,
                  method: actionModal?.method,
                  graphQLMethod: actionModal?.graphQLMethod,
                  isGraphQL: actionModal?.isGraphQL,
                }}
                onSave={async (payload: any) => {
                  proceedToNextAction.action = {
                    ...proceedToNextAction.action,
                    ...(isGraphQL ? { graphqlVariables: payload?.graphqlVariables } : { body: payload?.body }),
                    headers: payload?.headers,
                    source: payload?.uri,
                    method: actionModal?.method,
                    graphQLMethod: actionModal?.graphQLMethod,
                  };

                  await onAction(proceedToNextAction?.action, {});
                  setActionModal(null);
                  proceedToNextAction?.confirm();
                }}
                label={actionModal?.label || "Save"}
                onClose={() => handleClose()}
                isLoading={isLoading}
                disabled={disabled}
              />
            );
          }}
        </BXModal>
      )}

      {renderActionableComponent()}

      {isViewActionTriggered && (
        <ViewerModal
          action={actionView}
          collectionId={collectionId}
          sharedPageId={sharedPageId}
          path={path}
          keyComp={_key}
          viewName={viewName}
          actionSourceType={actionView?.actionSourceType}
          item={item}
          pageId={pageId}
          selectedView={selectedView}
          openView={isViewActionTriggered}
          onSelectRow={onSelectRow}
          views={views}
          withoutLabel={iconButton}
          iconColor='currentColor'
          buttonComponent={children}
          isUserInput={isUserInput}
          showHeader={!!actionView?.showHeader}
          buttonProps={{
            style: iconButton
              ? {
                  // backgroundColor: palette.primary.main, padding: 6
                }
              : { ...(!withBorder && { borderWidth: 0, justifyContent: "flex-start" }) },
            fullWidth,
            variant,
          }}
          modalSize={actionView?.modalSize}
          __data={__data}
          id={tableId}
          parentIds={[...parentIds, ...queryKeys]}
          clearActionView={() => {
            proceedToNextAction.confirm();
            setIsViewActionTriggered(false);
            setActionView(null);
            setSelectedView(null);
          }}
        />
      )}
    </>
  );
};
