import { gql } from "@apollo/client";
import { Box, Button, Typography } from "@mui/material";
import { AddressElement, CardCvcElement, CardExpiryElement, CardNumberElement, PaymentElement } from "@stripe/react-stripe-js";
import { IconX } from "@tabler/icons-react";
import _ from "lodash";
import { SnackbarContent } from "notistack";
import Papa from "papaparse";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { isHostAvailable } from "src/App";
import { BXEngine } from "src/BXEngine";
import { useBuildxContext } from "src/BXEngine/BuildxContext";
import { useAppState } from "src/features/appState/hooks";
import { getReferenceLevel } from "src/features/appState/utils";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import { apolloClient, encodeURLParams, replaceBaseUrl } from "src/features/buildxProvider/buildxProviderUtils";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { setEndUser } from "src/features/endUser/endUserSlice";
import useAuth from "src/hooks/useAuth";
import store from "src/store/store";
import { UIElement } from "src/types/UIElement";
import axiosServices from "src/utils/axios";
import extAxiosServices from "src/utils/axiosExt";
import { getAuth, handleGoogleSignInClick, loginToApp, logoutOfApp, registerAppDevice } from "src/utils/buildxProviderOperations";
import { getAuthorizationHeader, getLastKeyFromObject, handleCustomMessage, handleRefetchQueries } from "src/utils/generalUtils";
import { closeSnackbarRef, enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { actionTypes } from "src/views/pages/BuildX/AppBuilder/forms/CreateViewForm";
import { getSharedViews, updateStepperGroupIndex } from "src/views/pages/BuildX/FormBuilder/utils";
import { v4 as uuid } from "uuid";
import * as XLSX from "xlsx";
import { triggerActionableComponent } from "../FormBuilder/utils";
import { BXIcon } from "../Icon";
import { useReplaceDataPlaceholders } from "./ActionButton";

export const useActionHandler = props => {
  const {
    actionsMap = {},
    onStateChange = () => {},
    disabled = false,
    item,
    views,
    onSelectRow,
    pageId,
    handleSubmit,
    formData,
    parentIds,
    __data = {},
    closeModal = () => {},
    queryKeys = [],
    viewName,
    disableInternalAction,
    onActionClick,
    entries,
    selectedRows,
    path,
    _key,
    index,
    isChainMapped,
    actionsKey,
    tableAction,
    isLoadingForEntireChain,
    conditionKeyFromTable,
    element,
    stripe,
    elements,
    dataEntry,
    fromParent,
    viewNameOption,
    actionIndex,
    actions,
    interactionConfig,
    currentRepeatedItem,
    pageIdFromLayout,
    isComponentDisabled,
    componentVisibility,
  } = props;

  const [open, setOpen] = useState(false);
  const [jsonProgress, setJsonProgress] = useState<any[]>([]);

  const [proceedToNextAction, setProceedToNextAction] = useState<any>({});
  const [actionModal, setActionModal] = useState<any>();
  const [showModalForAction, setShowModalForAction] = useState<boolean>(false);
  const [isViewActionTriggered, setIsViewActionTriggered] = useState<boolean>(false);
  const [actionView, setActionView] = useState<any>();
  const [selectedView, setSelectedView] = useState<any>();

  let cancelWorkflow = false;
  let isConfirmationDenied = false;
  let dirtyConfirmationDenied = false;
  let validationError = false;
  let currentResponse = null;

  const location = useLocation();
  const navigate = useNavigate();
  const { resetState, removeDirty, watch, setValue, getValue, clearValidation, runAllValidations, produceTrigger, cleanTrigger } =
    useAppState();

  const isLoading = watch(`${pageId}.${viewName}.${_key}.isLoading`, { pageId, viewName });

  const allowedApps = useBuildxProviderValue("allowedApps");
  const isAdministrationMode = useBuildxProviderValue("isAdministrationMode");
  const currentApp = useBuildxProviderValue("currentApp");
  const viewsState = useBuildxProviderValue("viewsState");
  const currentProfileId = useBuildxProviderValue("currentProfileId");
  const queriesStateGraphQL = useBuildxProviderValue("queriesStateGraphQL");
  const loadingViews = useBuildxProviderValue("loadingViews");
  const fqdnApp = useBuildxProviderValue("fqdnApp");
  const isConfirmAction = useBuildxProviderValue("isConfirmAction");
  const actionConfirmControl = useBuildxProviderValue("actionConfirmControl");
  const appTokens = useBuildxProviderValue("appTokens");
  const appProfiles = useBuildxProviderValue("appProfiles");
  const { appRoutesMap } = useBuildxContext();

  const { isLoggedIn, setUserAuth, loginSuccess, loginFailure } = useAuth();
  const dispatch = useDispatch();

  const { replaceDataPlaceholders, replaceDataPlaceholdersRecursively, updateDataPlaceholders } = useReplaceDataPlaceholders(
    viewNameOption ?? { viewName: viewName }
  );

  const actionTrigger = watch(`${pageId}.${viewName}.${_key}.trigger`, { pageId, viewName });
  //Execute the action of the component on demand
  useEffect(() => {
    const executeActionOnTrigger = async () => {
      if (actionTrigger?.type === "action") {
        await executeChain?.(actionTrigger?.payload?.actionEvent);
        actionTrigger?.payload?.resolver?.();
        cleanTrigger(`${pageId}.${viewName}.${_key}`, { pageId, viewName });
      }
    };

    executeActionOnTrigger();
  }, [actionTrigger]);

  //Handler methods for all actions
  const checkUnprotected = () => {
    const currentLocation = window.location.pathname;

    if (appRoutesMap.current.hasOwnProperty(currentLocation)) {
      return appRoutesMap.current[currentLocation]?.isUnprotected;
    }

    return false;
  };

  const handleOnSuccess = async (action, response, variables) => {
    const { data, request, status, headers } = response;
    const currentLoadingViews = store.getState().buildxProvider.loadingViews;
    const updatedLoadingViews = { ...currentLoadingViews };
    updatedLoadingViews[`${pageId}-${viewName}`] = false;
    dispatch(
      setProviderState({
        loadingViews: updatedLoadingViews,
      })
    );
    if (_key) {
      currentResponse = { ...data, requestStatusCode: status };
    }
    if (tableAction?.action && action?.isResetEnabled) {
      resetState(`${pageId}.${viewName}`, pageId, viewName as string, "view");
    }

    if (variables?.isCsv) {
      variables?.then?.();
      setJsonProgress(prevProgress =>
        prevProgress.map(item => {
          if (item.id === variables?.csvRow?.id) {
            return { ...item, status: "Success" };
          }
          return item;
        })
      );
      onStateChange("normal");
      return;
    }
    if (_key) {
      const responseState = {
        _body: { ...data },
        _status: status,
        _headers: headers,
      };
      setValue(`${pageId}.${viewName}.${_key}.response`, responseState, { pageId, viewName });
    }

    const findKey = action?.statusMessages?.find((item: any) => item?.key == request?.status);
    const statusMessage = findKey?.value;
    const keyMessage = findKey?.key;
    const statusMessageReplace = replaceDataPlaceholders({
      queryString: statusMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      actionResponse: data,
      env: currentApp?.env,
      selectedRows,
      index,
      actionIndex,
      actions,
      pageIdFromLayout,
    });

    let customMessage = handleCustomMessage(action, keyMessage, statusMessageReplace);

    customMessage = replaceDataPlaceholders({
      queryString: customMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      actionResponse: data,
      env: currentApp?.env,
      selectedRows,
      index,
      actionIndex,
      actions,
      pageIdFromLayout,
    });

    if (action?.isDismissibleView != "No") {
      closeModal?.(data, false);
    }

    if (!_.isEmpty(tableAction?.action)) {
      const responseState = {
        _body: { ...data },
        _status: status,
        _headers: headers,
        _message: customMessage,
      };

      const isActionOnRowLevel = !tableAction?.action.isGlobal;
      const actionResponsePath = isActionOnRowLevel
        ? `${pageId}.${viewName}.actions[${actionIndex}].rows[${index}].response`
        : `${pageId}.${viewName}.actions[${actionIndex}].response`;

      setValue(actionResponsePath, responseState, { pageId, viewName, pageIdFromLayout });
    }

    if (action?.showSnackbar ?? true) {
      enqueueSnackbarRef?.(customMessage || statusMessageReplace || "Posted Successfully", {
        variant: "success",
      });
    }

    const isGraphQL = action?.isGraphQL || false;
    const key = `${pageId}-${viewName}`;
    const viewConfig = views?.find(view => view?.info?.viewName === viewName);
    if (viewConfig && viewConfig.dataSource?.sourceType != "NONE") {
      handleRefetchQueries({
        isGraphQL,
        queriesStateGraphQL,
        key,
        queryKeys,
        produceTrigger,
        pageId,
        viewName,
      });
    }

    if (parentIds) {
      parentIds.forEach((id: any) => {
        handleRefetchQueries({
          isGraphQL,
          queriesStateGraphQL,
          key,
          queryKeys: [id],
          produceTrigger,
          pageId,
          viewName,
        });
      });
    }

    if (tableAction?.action && action?.refreshActionView) {
      handleRefreshViews(tableAction?.action);
    }

    onStateChange("normal");
    // success: remove item from data
    if (action?.method.toLowerCase() === "delete") {
    } else if (action?.method.toLowerCase() === "post") {
      // update record because it is an item action
    } else if (action?.method.toLowerCase() === "put") {
      // update record because it is an item action
    } else if (action?.method.toLowerCase() === "patch") {
      // update record because it is an item action
    }
  };

  const handleOnError = (action, error, variables) => {
    const currentLoadingViews = store.getState().buildxProvider.loadingViews;
    const updatedLoadingViews = { ...currentLoadingViews };
    updatedLoadingViews[`${pageId}-${viewName}`] = false;
    dispatch(
      setProviderState({
        loadingViews: updatedLoadingViews,
      })
    );
    if (variables?.isCsv) {
      variables?.then?.();
      setJsonProgress(prevProgress =>
        prevProgress.map(item => {
          if (item.id === variables?.csvRow?.id) {
            return {
              ...item,
              status: "Error",
              __error: error,
            };
          }
          return item;
        })
      );
      onStateChange("normal");
      return;
    }
    onStateChange("normal");
    if (_key) {
      const errorState = {
        _body: error,
        _status: (error as any).status,
      };

      setValue(`${pageId}.${viewName}.${_key}.response`, errorState, { pageId, viewName });
    }
    const findKey = action?.statusMessages?.find((item: any) => item?.key == error?.requestStatusCode);
    const statusMessage = findKey?.value;
    const keyMessage = findKey?.key;

    const errorMessage = replaceDataPlaceholders({
      queryString: statusMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      actionResponse: error,
      env: currentApp?.env,
      selectedRows,
      index,
      actionIndex,
      actions,
      pageIdFromLayout,
    });

    let customMessage = handleCustomMessage(action, keyMessage, errorMessage);

    customMessage = replaceDataPlaceholders({
      queryString: customMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      actionResponse: error,
      env: currentApp?.env,
      selectedRows,
      index,
      actionIndex,
      actions,
      pageIdFromLayout,
    });

    if (_key) {
      const errorState = watch(`${pageId}.${viewName}.${_key}.response`, { pageId, viewName });
      setValue(`${pageId}.${viewName}.${_key}.response`, { ...errorState, _message: customMessage }, { pageId, viewName });
    }

    if (!_.isEmpty(tableAction?.action)) {
      const errorState = {
        _body: error,
        _status: (error as any).status,
        _message: customMessage,
      };

      const isActionOnRowLevel = !tableAction?.action.isGlobal;
      const actionResponsePath = isActionOnRowLevel
        ? `${pageId}.${viewName}.actions[${actionIndex}].rows[${index}].response`
        : `${pageId}.${viewName}.actions[${actionIndex}].response`;

      setValue(actionResponsePath, errorState, { pageId, viewName });
    }

    if (action?.showSnackbar ?? true) {
      enqueueSnackbarRef?.(
        customMessage ||
          errorMessage ||
          error?.response?.data?.error ||
          JSON.parse(error?.response?.config?.data || "{}")?.errorMessage ||
          "Wrong Services",
        {
          variant: "error",
        }
      );
    }

    if (error?.requestStatusCode === 401) {
      handleLogoutAction(action);
    }
  };

  const onAction = async (action, variables) => {
    const isGraphQL = action?.isGraphQL || false;
    const currentLoadingViews = store.getState().buildxProvider.loadingViews;
    const updatedLoadingViews = { ...currentLoadingViews };
    updatedLoadingViews[`${pageId}-${viewName}`] = true;
    dispatch(
      setProviderState({
        loadingViews: updatedLoadingViews,
      })
    );
    setValue(`${pageId}.${viewName}.${_key}.isLoading`, true, { pageId, viewName });
    const _appId = currentApp?.id ?? fqdnApp?.id;
    const isUnprotectedPage = checkUnprotected();
    if (fqdnApp && isUnprotectedPage) {
      await registerAppDevice?.(
        _appId as string,
        allowedApps,
        currentApp,
        currentProfileId,
        appProfiles,
        appTokens,
        replaceDataPlaceholdersRecursively,
        (currentApp ?? fqdnApp)?.appConfig?.auth?.deviceApi,
        currentApp ?? fqdnApp
      );
    }

    const { token: appAccessToken } = getAuth(_appId as string, currentProfileId, appProfiles, appTokens, currentApp) || {};

    const accessToken = appAccessToken || localStorage.getItem(_appId + `-${currentProfileId}-accessToken`);
    const deviceToken = localStorage.getItem(`${_appId}-accessToken-device`);

    const token = isUnprotectedPage ? deviceToken : accessToken || deviceToken;

    onStateChange("loading");

    let url = replaceDataPlaceholders({
      queryString: action?.source,
      item,
      viewsState,
      formData,
      pageId,
      __data,
      env: currentApp?.env,
      csvRow: variables?.csvRow,
      index,
      fromParent,
      dataEntry,
      selector: dataEntry ? `this.data.${dataEntry}[*]` : `this.data._body[*]`,
      actionIndex,
      actions,
      tableAction,
      pageIdFromLayout,
    });

    url = encodeURLParams(replaceBaseUrl(url, currentApp ?? fqdnApp));
    let data = undefined;

    const actionBody = isGraphQL ? action?.graphqlVariables : action?.body;

    const processedBody = replaceDataPlaceholdersRecursively({
      obj: JSON.parse(action?.showModal == "Yes" ? actionBody : actionBody || "{}"),
      viewsState,
      pageId,
      item,
      formData,
      __data,
      env: currentApp?.env,
      csvRow: variables?.csvRow,
      selectedRows,
      fallback: null,
      index,
      fromParent,
      dataEntry,
      actionIndex,
      actions,
      pageIdFromLayout,
      selector: dataEntry ? `this.data.${dataEntry}[*]` : `this.data._body[*]`,
    });

    const requestHeaders = {
      ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
      ...replaceDataPlaceholdersRecursively({
        obj: action?.showModal == "Yes" ? action?.headers : action?.headers,
        viewsState,
        pageId,
        item,
        formData,
        __data,
        env: currentApp?.env,
        csvRow: variables?.csvRow,
        selectedRows,
        index,
        fromParent,
        dataEntry,
        actionIndex,
        actions,
        pageIdFromLayout,
      }),
    };

    if (handleSubmit) {
      data = actionBody && processedBody;
    } else {
      data = actionBody && processedBody;
    }
    let response = {} as any;
    try {
      if (isGraphQL) {
        const gqlQuery = gql`
          ${action?.graphqlQuery}
        `;
        const isMutation = action?.graphQLMethod === "MUTATION" && action?.graphqlQuery?.includes("mutation");
        const operationFunction = (isMutation ? apolloClient.mutate : apolloClient.query) as any;
        const operationKey = isMutation ? "mutation" : "query";
        const graphQLContext = {
          uri: url,
          headers: requestHeaders,
        };
        response = await operationFunction({
          [operationKey]: gqlQuery,
          variables: data,
          context: graphQLContext,
        });
        if (variables?.onSuccess) {
          variables?.onSuccess(response?.data, variables);
        } else {
          handleOnSuccess(action, response, variables);
        }
      } else {
        response = await extAxiosServices.request({
          url: url,
          method: action?.method?.toLowerCase?.(),
          params: {
            skipAuthErrorClear: isHostAvailable,
          },
          headers: requestHeaders,
          data,
        });
        if (variables?.onSuccess) {
          variables?.onSuccess(response?.data, variables);
        } else {
          handleOnSuccess(action, response, variables);
        }
      }
    } catch (error) {
      cancelWorkflow = true;
      handleOnError(action, error, variables);
    } finally {
      setValue(`${pageId}.${viewName}.${_key}.isLoading`, false, { pageId, viewName });
      onStateChange("normal");
      const currentLoadingViews = store.getState().buildxProvider.loadingViews;
      const updatedLoadingViews = { ...currentLoadingViews };
      updatedLoadingViews[`${pageId}-${viewName}`] = false;
      dispatch(
        setProviderState({
          loadingViews: updatedLoadingViews,
        })
      );
    }
  };

  const handleSelectClick = action => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".csv, .xlsx";
    fileInput.addEventListener("change", e => handleFileSelect(action, e));
    fileInput.click();
  };

  const handleFileSelect = (action, event: any) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      if (selectedFile.name.endsWith(".csv") || selectedFile.name.endsWith(".xlsx")) {
        convertXlsxAndCsvToJson(action, selectedFile);
      } else {
        console.error("Unsupported file format");
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setJsonProgress([]);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const convertXlsxAndCsvToJson = async (action, file: File) => {
    const duration = action?.duration;

    const delayFactor = ~~duration || 50;

    const maxCalls = ~~action?.maxCalls || 1;

    const handleOpenLogic = async (jsonArray: any[], delayFactor: number) => {
      handleOpen();

      const totalRequests = jsonArray.length - 1;
      let currentIndex = 0;

      let activeThreads = maxCalls;
      const makeRequest = () => {
        let isOpen = false;
        setOpen(prev => {
          isOpen = prev;
          return prev;
        });
        if (!isOpen) return;

        const handleNextRequest = async () => {
          await new Promise(resolve => setTimeout(resolve, delayFactor));
          currentIndex += 1;
          if (totalRequests < currentIndex) {
            activeThreads -= 1;
            if ((activeThreads === 0 && action?.showSnackbar) ?? true) {
              const successMessage = "CSV Processing Completed Successfully!";
              enqueueSnackbarRef?.(successMessage, {
                variant: "success",
              });
            }
            return;
          }
          makeRequest();
        };
        setJsonProgress((prevProgress: any[]) => [jsonArray[currentIndex], ...prevProgress]);
        onAction(action, { csvRow: jsonArray[currentIndex], isCsv: true, then: handleNextRequest });
      };

      for (let index = 0; index < maxCalls; index++) {
        if (totalRequests < currentIndex) return;
        makeRequest();
        currentIndex += 1;
      }
    };

    const reader = new FileReader();

    reader.onload = async event => {
      if (event.target && event.target.result instanceof ArrayBuffer) {
        const data = event.target.result;
        const workbook = XLSX.read(new Uint8Array(data), { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        let jsonArray: any[] = [];

        if (file.name.endsWith(".xlsx")) {
          jsonArray = XLSX.utils.sheet_to_json(worksheet).map((row: any) => ({
            __id: uuid(),
            ...row,
            status: "Pending",
          }));
          handleOpenLogic([...jsonArray], delayFactor);
        } else if (file.name.endsWith(".csv")) {
          Papa.parse(file, {
            complete: async (result: any) => {
              if (result.data && result.data.length > 1) {
                const keys: string[] = result.data[0];

                const statusIndex = keys.indexOf("status");
                if (statusIndex !== -1) {
                  keys.splice(statusIndex, 1);
                  keys.push("status");
                }

                for (let i = 1; i < result.data.length - 1; i++) {
                  const rowData: any[] = result.data[i];
                  const obj: any = {
                    __id: uuid(),
                  };
                  keys.forEach((key, j) => {
                    obj[key] = rowData[j];
                  });
                  obj.status = "Pending";
                  jsonArray.push(obj);
                }
                handleOpenLogic([...jsonArray], delayFactor);
              } else {
                console.error("Error reading file");
              }
            },
          });
        }
      } else {
        console.error("Error reading file");
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const downloadCSV = () => {
    if (entries?.length === 0) {
      return;
    }

    const keys = Object.keys(entries[0]);

    const csvContent = [keys.join(","), ...entries.map((entry: any) => keys.map(key => entry[key]).join(","))].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  };

  const handleResetForm = async action => {
    const inputReference = action?.dataReference ?? "";
    const elementKey = replaceNamePlaceHolder(inputReference);

    const resetLevel = getReferenceLevel(inputReference);

    if (elementKey) {
      resetState(elementKey, pageId, viewName as string, resetLevel);
    } else {
      // handle old reset state without key item logic
      resetState(`${pageId}.${viewName}`, pageId, viewName as string, "view");
    }
  };

  const handleActionClick = async action => {
    if (loadingViews[`${pageId}-${viewName}`]) return;
    if (action.enableCSV && action?.actionSourceType?.id !== -3) {
      handleSelectClick(action);
      return;
    }

    if (action?.actionSourceType?.id === -3) {
      downloadCSV();
      return;
    }

    if (handleSubmit) {
      const { isError, values } = handleSubmit();
      if (isError) {
        cancelWorkflow = true;
        validationError = true;
        return;
      }

      await onAction(action, {});
    } else {
      await onAction(action, {});
    }
  };

  const handleStripePayment = async action => {
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const addressElement = elements.getElement(AddressElement);
    const paymentElement = elements.getElement(PaymentElement);

    if (cardNumberElement && cardCvcElement && cardExpiryElement) {
      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
      });
    } else if (addressElement || paymentElement) {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: { return_url: "" },
        redirect: "if_required",
      });
    } else {
      console.error("Missing required elements");
      return;
    }
  };

  const moveGroupStepperIndexBy = (offset: number, _action: any) => {
    const stepperPath = viewName
      ? `${pageId}.${viewName}.${_action?.stepperGroupReference?.key}.state`
      : `${pageId}.${_action?.stepperGroupReference?.key}.state`;

    const newStepper = updateStepperGroupIndex(getValue(stepperPath, { pageId, viewName }) || {}, "UPDATE", offset);

    setValue(stepperPath, newStepper, {
      pageId,
      viewName,
      reEvaluateErrorsAndDirty: true,
    });
  };

  const setStepperIndex = (index: number, _action: any) => {
    const stepperPath = viewName
      ? `${pageId}.${viewName}.${_action?.stepperGroupReference?.key}.state`
      : `${pageId}.${_action?.stepperGroupReference?.key}.state`;

    const newStepper = updateStepperGroupIndex(getValue(stepperPath, { pageId, viewName }) || {}, "SET", index);

    setValue(stepperPath, newStepper, {
      pageId,
      viewName,
      reEvaluateErrorsAndDirty: true,
    });
  };

  const handleSetStepperIndex = _action => {
    setStepperIndex(_action?.stepperIndex, _action);
  };

  const handleStepperPrevious = _action => {
    moveGroupStepperIndexBy(-1, _action);
  };
  const handleStepperNext = _action => {
    moveGroupStepperIndexBy(1, _action);
  };

  const addItem = action => {
    updateDataPlaceholders({
      queryString: action.dataReference,
      item,
      viewsState,
      pageId,
      __data,
      index,
      $action: "ADD",
      pageIdFromLayout,
    });
  };

  const trigger = async (action, e, viewsState, wait?: any) => {
    const triggerSelf = action.triggerSelf;
    const key = triggerSelf ? _key : action.triggerKey;

    await new Promise<void>(resolve => {
      setTimeout(async () => {
        if (wait) {
          await triggerActionableComponent(e, pageId, viewName, key, viewsState, produceTrigger);
        } else {
          triggerActionableComponent(e, pageId, viewName, key, viewsState, produceTrigger);
        }
        resolve();
      }, 0);
    });
  };

  const deleteItem = action => {
    updateDataPlaceholders({
      queryString: action.dataReference,
      item,
      viewsState,
      pageId,
      __data,
      index,
      $action: "DELETE",
      pageIdFromLayout,
    });
  };

  const updateItem = action => {
    let updatedValue;
    if (action.selectedType === "object") {
      const object = JSON.parse(action.updatedValue);
      updatedValue = replaceDataPlaceholdersRecursively({
        obj: object,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        selectedRows,
        actionIndex,
        actions,
        index,
        selector: dataEntry ? `this.data.${dataEntry}[*]` : `this.data._body[*]`,
        pageIdFromLayout,
      });
    } else {
      updatedValue = replaceDataPlaceholders({
        queryString: action.updatedValue,
        item,
        viewsState,
        formData,
        pageId,
        __data,
        index,
        actionIndex,
        actions,
        pageIdFromLayout,
      });
    }

    if (action.selectedType === "number") {
      updatedValue = Number(updatedValue);
    }

    updateDataPlaceholders({
      queryString: action.dataReference,
      item,
      viewsState,
      pageId,
      __data,
      index,
      $action: "UPDATE",
      $payload: updatedValue,
      actionIndex,
      actions,
      pageIdFromLayout,
    });
  };

  const cleanDirty = action => {
    const inputReference = action?.dataReference ?? "";
    const elementKey = replaceNamePlaceHolder(inputReference);
    const cleanDirtyLevel = getReferenceLevel(inputReference);

    if (elementKey) {
      removeDirty(elementKey, pageId, viewName, cleanDirtyLevel);
    }
  };

  const timerAction = async action => {
    const timer = Number(action?.timerValue);
    const sec = timer * 1000;
    await new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, sec);
    });
  };

  const handleCopyClick = action => {
    const copySourceValue =
      replaceDataPlaceholders({
        queryString: action.copySource,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        selectedRows,
        index,
        fromParent,
        dataEntry,
        actionIndex,
        actions,
        pageIdFromLayout,
      }) || "";

    if (copySourceValue) {
      navigator.clipboard
        .writeText(copySourceValue)
        .then(() => {
          if (action?.showSnackbar ?? true) {
            enqueueSnackbarRef("Copied!", { variant: "success" });
          }
        })
        .catch(err => {
          cancelWorkflow = true;
          if (action?.showSnackbar ?? true) {
            enqueueSnackbarRef("Failed to copy!", { variant: "error" });
          }
        });
    }
  };
  const showSnackbar = action => {
    const app = currentApp ?? fqdnApp;

    type VerticalPosition = "top" | "bottom";
    type HorizontalPosition = "left" | "center" | "right";
    const customMessage = replaceDataPlaceholders({
      queryString: action.snackbarMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      env: app?.env,
      selectedRows,
      index,
      fromParent,
      dataEntry,
      actionIndex,
      actions,
      pageIdFromLayout,
    });

    const defaultVariants = ["default", "success", "error", "info", "warning"];

    const duration = action.snackbarDuration || app?.appConfig?.snackbar?.duration;
    const durationInMs = Number(duration) * 1000;
    const customVariant = app?.appConfig?.snackbar?.allVariants?.includes(action?.snackbarVariant)
      ? action.snackbarVariant
      : app?.appConfig?.snackbar?.variant;

    const position = action?.snackbarPosition == "Inherent from app" ? app?.appConfig?.snackbar?.position : action?.snackbarPosition;
    const actionButton =
      action.snackbarActionButton == "Inherent from app" ? app?.appConfig?.snackbar?.closeButton : action.snackbarActionButton;
    const preventDuplicate = action.snackbarPreventDuplicate;
    const [vertical, horizontal] = position?.split("-") as [VerticalPosition, HorizontalPosition];
    const isCustom = action?.isCustom;
    const variantsSettings = app?.appConfig?.snackbar?.variantsSettings;
    const customPosition = isCustom ? action?.alignText : variantsSettings?.[customVariant]?.alignText;
    const customBgColor = isCustom ? action?.backgroundColorAdvance : variantsSettings?.[customVariant]?.backgroundColorAdvance;
    const customColor = isCustom ? action?.colorAdvance : variantsSettings?.[customVariant]?.colorAdvance;
    const customIcon = variantsSettings?.[customVariant]?.iconAdvance;
    const iconColor = isCustom ? action?.iconColor : variantsSettings?.[customVariant]?.iconColor;

    enqueueSnackbarRef?.(customMessage, {
      variant: customVariant,
      anchorOrigin: {
        vertical,
        horizontal,
      },
      ...(duration && { autoHideDuration: durationInMs }),
      ...(actionButton !== "Without Button" && {
        action: key =>
          actionButton == "Icon X" || actionButton == "Dismiss" ? (
            <Button
              onClick={() => {
                closeSnackbarRef(key);
              }}
            >
              {actionButton == "Icon X" && <IconX color='white' size={18} />}
              {actionButton == "Dismiss" && <Typography color={"white"}>Dismiss</Typography>}
            </Button>
          ) : (
            <Box
              style={{
                overflow: "hidden",
                height: "100%",
                cursor: "pointer",
                position: "absolute",
                top: "5px",
                right: "5px",
              }}
            >
              <IconX
                color='white'
                size={14}
                onClick={() => {
                  closeSnackbarRef(key);
                }}
              />
            </Box>
          ),
      }),
      ...(actionButton == "Without Button" && {
        action: key => <></>,
      }),

      preventDuplicate,
      ...((!defaultVariants.includes(customVariant) || isCustom) && {
        content: (key, message) => {
          return (
            <SnackbarContent
              style={{
                backgroundColor: customBgColor || "",
                color: customColor || "",
                width: "100%",
                boxSizing: "border-box",
                margin: "8px",
                padding: "12px 16px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "5px",
                position: "relative",
              }}
            >
              <Box
                style={{
                  width: actionButton == "Without Button" || actionButton == "Corner X" ? "100%" : "auto",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: customPosition || "left",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <BXIcon
                  width={"25px"}
                  height={"25px"}
                  icon={isCustom ? action?.iconAdvance?.icon : variantsSettings?.[customVariant]?.iconAdvance?.icon}
                  url={isCustom ? action?.iconAdvance?.url : variantsSettings?.[customVariant]?.iconAdvance?.url}
                  visibility={isCustom ? action?.iconAdvance?.visibility : variantsSettings?.[customVariant]?.iconAdvance?.visibility}
                  color={iconColor || "white"}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
                {customMessage}
              </Box>
              {actionButton !== "Without Button" && actionButton == "Corner X" && (
                <Box
                  style={{
                    overflow: "hidden",
                    height: "100%",
                    cursor: "pointer",
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                  }}
                >
                  <IconX
                    color='#8a8a8a'
                    size={14}
                    onClick={() => {
                      closeSnackbarRef(key);
                    }}
                  />
                </Box>
              )}

              {actionButton !== "Without Button" && (actionButton == "Icon X" || actionButton == "Dismiss") && (
                <Button
                  onClick={() => {
                    closeSnackbarRef(key);
                  }}
                >
                  {actionButton == "Icon X" && <IconX color='black' size={18} />}
                  {actionButton == "Dismiss" && <Typography color={"black"}>Dismiss</Typography>}
                </Button>
              )}
            </SnackbarContent>
          );
        },
      }),
    });
  };

  const stopWorkflow = action => {
    cancelWorkflow = true;
  };

  const handleLinkClick = async action => {
    if (!action?.navigationAfterAction && action?.actionSourceType?.type !== "Link") return;

    const linkUrlValue =
      replaceDataPlaceholders({
        queryString: action?.linkUrl,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        fallback: "",
        index,
        selector: dataEntry ? `this.data.${dataEntry}[*]` : `this.data._body[*]`,
        fromParent,
        dataEntry,
        actionIndex,
        actions,
        tableAction,
        actionResponse: currentResponse,
        pageIdFromLayout,
      }) || "";

    if (linkUrlValue) {
      const path = encodeURLParams(replaceBaseUrl(linkUrlValue, undefined, fqdnApp ? "" : currentApp?.slug));
      if (linkUrlValue.startsWith("/")) {
        let updatedValue;
        if (action.selectedType === "object") {
          const object = JSON.parse(action.updatedValue);
          updatedValue = replaceDataPlaceholdersRecursively({
            obj: object,
            item,
            viewsState,
            pageId,
            __data,
            env: currentApp?.env,
            selectedRows,
            index,
            pageIdFromLayout,
          });
        } else {
          updatedValue = replaceDataPlaceholders({
            queryString: action.updatedValue,
            item,
            viewsState,
            formData,
            pageId,
            __data,
            index,
            actionIndex,
            actions,
            pageIdFromLayout,
          });
        }
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            try {
              if (action?.openLinkAs == "_blank") {
                resolve(window.open(path, "_blank"));
              } else {
                resolve(
                  navigate(path, {
                    state: updatedValue,
                  })
                );
              }
            } catch (error) {
              reject(error);
            }
          }, 0);
        });
      } else {
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            try {
              resolve(window.open(path, action?.openLinkAs || "_blank"));
            } catch (error) {
              reject(error);
            }
          }, 0);
        });
      }
    } else {
      cancelWorkflow = true;
      if (action?.showSnackbar ?? true) {
        enqueueSnackbarRef("Invalid URL!", { variant: "error" });
      }
    }
  };

  const handleRefreshViews = async action => {
    const isGraphQL = action?.isGraphQL || false;

    const _refreshList =
      typeof action?.refreshActionView?.[0] === "string"
        ? action?.refreshActionView.map(name => {
            const { id } = views?.find(view => view?.info?.name === name) as UIElement;
            return {
              id: id,
              name,
            };
          })
        : action?.refreshActionView;

    const refreshViewsIds = new Set(_refreshList.map(v => v?.id));
    const refreshViews = views?.filter(view => refreshViewsIds.has(view?.id));

    refreshViews?.forEach(async refreshView => {
      const refreshViewName = refreshView?.info?.viewName;
      const refreshKey = `${pageId}.${refreshViewName}`;

      await handleRefetchQueries({
        isGraphQL,
        queriesStateGraphQL,
        key: refreshKey,
        queryKeys: refreshKey,
        produceTrigger,
        pageId,
        viewName: refreshViewName,
      });
    });
  };

  const downloadFile = async (url, showSnackbar, downloadedName, downloadKeyObj) => {
    try {
      const response = await fetch(url, {
        headers: downloadKeyObj.headers,
      });
      const blob = await response.blob();
      let resolvedFileName = replaceDataPlaceholders({
        queryString: downloadedName || "",
        item,
        viewsState,
        pageId,
        __data,
        fallback: "",
        index,
        actionIndex,
        selector: dataEntry ? `this.data.${dataEntry}[*]` : `this.data._body[*]`,
        pageIdFromLayout,
      });
      let filename = resolvedFileName || blob.type || "downloaded_file";

      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      cancelWorkflow = true;
      if (showSnackbar) {
        enqueueSnackbarRef("Failed to download!", { variant: "error" });
      }
    }
    return;
  };

  const handleDownloadClick = async action => {
    if (action?.downloadType === "api") {
      await onAction(action, {
        onSuccess: (data, key) => {
          const responseState = {
            _body: { ...data },
          };

          const actionResponsePath = `${pageId}.${viewName}.actions[${actionIndex}].rows[${index}].response`;
          setValue(actionResponsePath, responseState, { pageId, viewName });

          let resolvedKey = replaceDataPlaceholders({
            queryString: action.downloadKey.source,
            actionResponse: _.get(viewsState, `${pageId}-${viewName}.response.${_key}`),
            item,
            viewsState,
            pageId,
            formData,
            __data,
            env: currentApp?.env,
            selectedRows,
            selector: dataEntry ? `this.data.${dataEntry}[*]` : `this.data._body[*]`,
            index,
            actionIndex,
            actions,
            pageIdFromLayout,
          });
          downloadFile(resolvedKey, action?.showSnackbar ?? true, action.downloadedName, action.downloadKey);
        },
      });
      return;
    }

    let resolvedDirectUrl = replaceDataPlaceholders({
      queryString: action?.directUrl || "",
      actionResponse: _.get(viewsState, `${pageId}-${viewName}.response.${_key}`),
      item,
      viewsState,
      pageId,
      formData,
      __data,
      env: currentApp?.env,
      selectedRows,
      selector: dataEntry ? `this.data.${dataEntry}[*]` : `this.data._body[*]`,
      index,
      actionIndex,
      actions,
      pageIdFromLayout,
    });
    downloadFile(resolvedDirectUrl, action?.showSnackbar ?? true, action.downloadedName, "");
  };

  const handleStackClick = (action, e) => {
    e.stopPropagation();
    onSelectRow?.(item, true);
    const currentViewStacks = store.getState().buildxProvider.viewStacks;
    const newViewStackElement = [
      ...currentViewStacks,
      <BXEngine
        path={[path, selectedView?.info?.name].join(".")}
        auth={{}}
        page={{ views, layout: selectedView, id: pageId } as any}
        layout={[{ ...(selectedView as any), type: action?.actionSourceType?.type }]}
        isVisible
        selectedViewId={item.id}
        __data={{
          ...__data,
          [(getLastKeyFromObject(__data) || "") + "#."]: viewName,
        }}
        closeModal={closeModal}
        parentIds={[...parentIds, ...queryKeys]}
      />,
    ] as UIElement[];
    dispatch(
      setProviderState({
        viewStacks: newViewStackElement,
      })
    );
  };

  const handleLoginAction = async action => {
    setValue(`${pageId}.${viewName}.${_key}.isLoading`, true, { pageId, viewName });
    const email = replaceDataPlaceholders({
      queryString: action?.BXLogin?.emailField,
      viewsState,
      pageId,
      __data,
      env: currentApp?.env,
      fallback: "",
      actionIndex,
      actions,
      pageIdFromLayout,
    });

    const password = replaceDataPlaceholders({
      queryString: action?.BXLogin?.passwordField,
      viewsState,
      pageId,
      __data,
      env: currentApp?.env,
      fallback: "",
      actionIndex,
      actions,
      pageIdFromLayout,
    });

    await registerAppDevice?.(
      currentApp?.id || fqdnApp?.id || "",
      allowedApps,
      currentApp,
      currentProfileId,
      appProfiles,
      appTokens,
      replaceDataPlaceholdersRecursively,
      fqdnApp?.appConfig?.auth?.deviceApi,
      fqdnApp
    );
    const response = await loginToApp?.(
      currentApp?.id || fqdnApp?.id || "",
      allowedApps,
      currentApp,
      isAdministrationMode,
      location,
      navigate,
      appRoutesMap,
      currentProfileId,
      replaceDataPlaceholdersRecursively,
      loginSuccess,
      loginFailure,
      email,
      password,
      undefined,
      undefined,
      () => {},
      undefined,
      fqdnApp?.appConfig?.auth?.authApi,
      fqdnApp,
      undefined,
      {
        skipAuthErrorClear: true,
      },
      action?.BXLogin?.disableAutoNavigate
    );

    if (_key) {
      const currentViewsState = store.getState().buildxProvider.viewsState;
      const updatedViewsState = { ...currentViewsState };
      _.set(updatedViewsState, `${pageId}-${viewName}.response.${_key}`, {
        ...response,
        requestStatusCode: response?.request?.code,
      });
      dispatch(
        setProviderState({
          viewsState: updatedViewsState,
        })
      );
    }

    if (_key) {
      const responseState = {
        _body: { ...(response?.data || response) },
        _status: response?.request?.code || response?.requestStatusCode || response?.status,
      };

      setValue(`${pageId}.${viewName}.${_key}.response`, responseState, { pageId, viewName });
    }

    setValue(`${pageId}.${viewName}.${_key}.isLoading`, false, { pageId, viewName });
  };

  const handleGoogleSignInAction = (fqdnApp: any) => {
    const { googleInfo } = fqdnApp?.appConfig || {};
    const { clientId } = googleInfo || {};
    const { fqdn } = fqdnApp || {};
    const redirectUri = fqdn + "/auth/google";
    if (clientId && redirectUri) {
      handleGoogleSignInClick(redirectUri, clientId);
    }
  };

  const handleOpenModalAction = action => {
    const { selectedModal } = action?.actionSourceType || {};
    if (selectedModal) {
      const currentViewsState = store.getState().buildxProvider.viewsState;
      const viewRef = `${pageId}-${viewName}`;
      const updatedViewsState = {
        ...currentViewsState,
        state: currentViewsState.state,
        visibility: {
          ...currentViewsState.visibility,
          [viewRef]: {
            ...currentViewsState.visibility?.[viewRef],
            [selectedModal]: {
              display: "block",
            },
          },
        },
      };

      dispatch(
        setProviderState({
          viewsState: updatedViewsState,
        })
      );
    }
    setValue(`${pageId}.${viewName}.${selectedModal}.props.sx.visibility`, "visible", {
      pageId,
      viewName,
    });
  };

  const handleDismissAction = (action, e) => {
    const { selectedModal } = action?.actionSourceType || {};
    if (!selectedModal) {
      closeModal?.(null);
    }
    if (selectedModal) {
      e?.stopPropagation();
      const currentViewsState = store.getState().buildxProvider.viewsState;
      const viewRef = `${pageId}-${viewName}`;
      const updatedVisibility = { ...currentViewsState.visibility?.[viewRef] };

      const updatedViewsState = {
        ...currentViewsState,
        state: currentViewsState.state,
        visibility: {
          ...currentViewsState.visibility,
          [viewRef]: {
            ...updatedVisibility,
            [selectedModal]: {
              display: "none",
            },
          },
        },
      };

      dispatch(
        setProviderState({
          viewsState: updatedViewsState,
        })
      );
    }
    setValue(`${pageId}.${viewName}.${selectedModal}.props.sx.visibility`, "hidden", {
      pageId,
      viewName,
    });
  };

  const handleLogoutAction = async action => {
    const isFQDN = !!fqdnApp;
    if (isFQDN) {
      setUserAuth();
    }
    await logoutOfApp?.(currentApp?.id as string, currentProfileId, !!isFQDN);
    if (!isFQDN) {
      dispatch(
        setProviderState({
          currentProfileId: null,
        })
      );
    }
    const currentAppProfiles = store.getState().buildxProvider.appProfiles;
    const updatedAppProfiles = { ...currentAppProfiles };
    delete updatedAppProfiles[currentApp?.id as string];
    dispatch(
      setProviderState({
        appProfiles: updatedAppProfiles,
      })
    );
    if (isHostAvailable || (currentApp?.appConfig?.withProfiles && currentApp?.appConfig?.isSingleSignOn)) {
      await axiosServices.delete(`application/${currentApp?.id}/profile/${currentProfileId}`);
    }

    localStorage.removeItem("targetPathAfterLogin");
  };

  const handleStartLoading = () => {
    //Change loading state for page and view

    setValue(`${pageId}.isLoading`, true, { pageId, viewName });
    setValue(`${pageId}.${viewName}.isLoading`, true, { pageId, viewName });
  };

  const handleEndLoading = () => {
    //Change loading state for page and view

    setValue(`${pageId}.isLoading`, false, { pageId, viewName });
    setValue(`${pageId}.${viewName}.isLoading`, false, { pageId, viewName });
  };

  const handleVisibilityToggle = action => {
    const keyReference = action.keyReference;
    if (keyReference) {
      const currentViewsState = store.getState().buildxProvider.viewsState;
      const viewRef = `${pageId}-${viewName}`;
      const updatedViewsState = {
        ...currentViewsState,
        state: currentViewsState.state,
        visibility: {
          ...currentViewsState.visibility,
          [viewRef]: {
            ...currentViewsState.visibility?.[viewRef],
            [keyReference]: {
              toggle: !currentViewsState.visibility?.[viewRef]?.[keyReference]?.toggle,
              display: action.displayRef ? "hidden" : "none",
            },
          },
        },
      };

      // Dispatch the updated views state to the Redux store
      dispatch(
        setProviderState({
          viewsState: updatedViewsState,
        })
      );
    }
  };

  const handleConfirmAction = async () => {
    actionConfirmControl.confirm();
    dispatch(
      setProviderState({
        isConfirmAction: null,
      })
    );
  };

  const handleCancelAction = async () => {
    actionConfirmControl.cancel();
    dispatch(
      setProviderState({
        isConfirmAction: null,
      })
    );
  };

  const replaceNamePlaceHolder = (dataSourceKey: string) => {
    let elementKey = "";

    if (dataSourceKey.startsWith("{this")) {
      if (dataSourceKey === "{this}") {
        //Current View
        elementKey = `${pageId}.${viewName}`;
      } else {
        //Component in the current view
        const placeholder = dataSourceKey.slice("{this.".length, -1);
        elementKey = `${pageId}.${viewName}.${placeholder}`;
      }
    } else if (dataSourceKey === "{$page}") {
      //Current Page
      elementKey = `${pageId}`;
    } else {
      //Another view in the same page
      dataSourceKey.replace(/{(\w+)(\.\w+)?}/g, (_match, view, placeholder) => {
        elementKey = placeholder ? `${pageId}.${view}${placeholder}` : `${pageId}.${view}`;
        return elementKey;
      });
    }
    return elementKey;
  };

  const handleTriggerDataSource = async action => {
    //Read data source key.
    const dataSourceKey = action?.dataSourceKey;
    const elementKey = replaceNamePlaceHolder(dataSourceKey);
    const viewOfDataSource = elementKey.split(".")[1]; //{pageId.<viewName>}

    await new Promise((resolve, reject) => {
      produceTrigger(
        elementKey,
        "refetch",
        { resolver: resolve },
        {
          pageId,
          viewName: viewOfDataSource,
        }
      );
    });
  };

  const handleRunValidation = action => {
    const validationKey = action?.dataReference;
    const elementKey = replaceNamePlaceHolder(validationKey);
    const validationLevel = getReferenceLevel(action?.dataReference);

    const result = runAllValidations(elementKey, validationLevel);
    validationError = !result;

    const scrollToErrorEnabled = action?.scrollToError ?? true;
    if (scrollToErrorEnabled) {
      setTimeout(() => {
        const firstInvalidField = document.querySelector('[aria-invalid="true"]');

        if (firstInvalidField) {
          firstInvalidField.scrollIntoView({ behavior: "smooth", block: "center" });
          (firstInvalidField as HTMLElement).focus();
        }
      }, 0);
    }
  };
  const handleCheckDirty = action => {
    //Read reference
    const validationKey = action?.dataReference;
    const elementKey = replaceNamePlaceHolder(validationKey);

    const isDirtyResult = getValue(`${elementKey}.isDirty`, { pageId, viewName });

    if (isDirtyResult) {
      // eslint-disable-next-line no-restricted-globals
      if (!confirm("There are some changes. Are you sure you want to navigate?")) {
        dirtyConfirmationDenied = true;
      } else {
        removeDirty(elementKey, pageId, viewName, getReferenceLevel(validationKey));
      }
    }
  };
  const handleNextPageAction = async action => {
    const dataSource = replaceNamePlaceHolder(action?.dataSourceKey);
    const paginationBar = replaceNamePlaceHolder(action?.paginationBarKey);

    const paginationBarState = getValue(paginationBar, { pageId, viewName });
    const currentPage = paginationBarState?.state?.currentPage;
    const hasMore = paginationBarState?.state?.hasMore;

    if (!hasMore) {
      return;
    }

    await new Promise(resolve => {
      produceTrigger(paginationBar, "updatePage", { resolver: resolve, page: currentPage + 1 }, { pageId, viewName });
    });
  };

  const handlePreviousPageAction = async action => {
    const dataSource = replaceNamePlaceHolder(action?.dataSourceKey);
    const paginationBar = replaceNamePlaceHolder(action?.paginationBarKey);

    const paginationBarState = getValue(paginationBar, { pageId, viewName });
    const currentPage = paginationBarState?.state?.currentPage;
    const hasMore = paginationBarState?.state?.hasMore;

    if (currentPage == 1) {
      return;
    }

    await new Promise(resolve => {
      produceTrigger(paginationBar, "updatePage", { resolver: resolve, page: currentPage - 1 }, { pageId, viewName });
    });
  };
  const fetchNextPage = async action => {
    const elementKey = `${pageId}.${viewName}.${action.dataSourceComponentKey}`;
    const InfoForElement = getValue(`${elementKey}.data._pagination.nextCursor`, { pageId, viewName });
    const hasMore = getValue(`${elementKey}.data._pagination.hasMore`, { pageId, viewName });

    if (hasMore) {
      await new Promise((resolve, reject) => {
        produceTrigger(
          elementKey,
          "fetchNextPage",
          {
            resolver: resolve,
            currentCursorParam: InfoForElement,
            isPagination: false,
            isInfiniteScrollTableContainer: true,
          },
          { pageId, viewName }
        );
      });
    }
  };

  const handleClearValidation = action => {
    const elementKey = replaceNamePlaceHolder(action?.dataReference);

    if (elementKey) {
      clearValidation(elementKey);
    }
  };

  const handleTriggerAction = async (action, e, viewsState) => {
    if (!action.triggerSelf && action.triggerAwait && action.triggerKey !== _key) {
      const wait = true;
      await trigger(action, e, viewsState, wait);
    } else {
      trigger(action, e, viewsState);
    }
  };

  const handleViewAction = async action => {
    const _views = [...(getSharedViews(currentApp) || []), ...views];
    setSelectedView(_views?.find(view => view?.id === action?.actionSourceType?.id));
    setActionView(action);
    setIsViewActionTriggered(true);

    await createActionPromise(action);
  };

  const handleApiWithModal = async action => {
    setActionModal(action);
    setShowModalForAction(true);

    await createActionPromise(action);
  };

  const createActionPromise = (action, title = "") =>
    new Promise(resolve => {
      setProceedToNextAction({
        currentAction: action,
        confirm: () => resolve(true),
        cancel: () => {
          cancelWorkflow = true;
          resolve(false);
        },
      });
      dispatch(
        setProviderState({
          actionConfirmControl: {
            currentAction: action,
            title,
          },
        })
      );
    });

  const handleActionMapper = async (action, e, viewsState) => {
    if (!action) return;
    const isNoneActionType = !action?.actionSourceType?.type || action.actionSourceType.type === "None";
    if (isNoneActionType) return;
    const isPureAction = actionTypes?.some(type => type?.id === action?.actionSourceType?.id);
    const isViewAction = !isPureAction;

    if (isViewAction) {
      await handleViewAction(action);
      return;
    }

    if (action?.actionSourceType?.type === "API") {
      if (action?.dialog?.enabled) {
        const confirmationDialogData = replaceDataPlaceholdersRecursively({
          obj: _.cloneDeep(action?.dialog),
          item,
          viewsState,
          pageId,
          __data,
          env: currentApp?.env,
          selectedRows,
          index,
          selector: dataEntry ? `this.data.${dataEntry}[*]` : `this.data._body[*]`,
        });

        //Store confirmation state in app state for later referencing in the dialog view if any.
        if (!confirmationDialogData?.customViewEnabled) {
          setValue(`${pageId}.${viewName}.confirmation`, confirmationDialogData, { pageId, viewName });
        } else {
          const _views = views?.concat(getSharedViews(currentApp));
          const confirmationViewName = _views?.find(v => v?.id === action?.dialog?.customView)?.info?.viewName;
          dispatch(
            setEndUser({
              pageId: pageId,
              viewName: confirmationViewName,
            })
          );
          setValue(`${pageId}.${confirmationViewName}.confirmation`, confirmationDialogData, {
            pageId,
            viewName: confirmationViewName,
          });
        }

        dispatch(
          setProviderState({
            isConfirmAction: tableAction?.action ? _key : element?.id,
          })
        );
        await new Promise(resolve => {
          const updatedActionConfirmControl = {
            currentAction: action,
            title: confirmationDialogData?.title,
            confirmationDialogData: confirmationDialogData,
            confirm: async () => {
              await handleActionClick(action);
              resolve(true);
            },
            cancel: () => {
              cancelWorkflow = true;
              // When the user denies of the confirmation dialog, suspend the subsequent actions
              isConfirmationDenied = true;
              resolve(false);
            },
          };

          dispatch(
            setProviderState({
              actionConfirmControl: updatedActionConfirmControl,
            })
          );
        });
        return;
      }

      if (action?.showModal === "Yes") {
        await handleApiWithModal(action);
        return;
      }
    }

    const isLoading = getValue(`${pageId}.${viewName}.${_key}.isLoading`, { pageId, viewName });
    if (isLoading || disabled) return;

    const handlers = {
      API: handleActionClick,
      "Stripe Payment": handleStripePayment,
      Views: handleViewAction,
      Download: handleDownloadClick,
      Copy: handleCopyClick,
      "Show Snackbar": showSnackbar,
      Link: handleLinkClick,
      "BuildX Login": handleLoginAction,
      "Google Sign In": () => handleGoogleSignInAction(fqdnApp),
      "BuildX Logout": handleLogoutAction,
      Stack: handleStackClick,
      "Visibility Toggle": handleVisibilityToggle,
      "Export CSV": handleActionClick,
      "Add item": addItem,
      "Delete item": deleteItem,
      "Update item": updateItem,
      Trigger: handleTriggerAction,
      "Clean Dirty": cleanDirty,
      Timer: timerAction,
      Dismiss: () => handleDismissAction(action, e),
      "Start Loading": handleStartLoading,
      "End Loading": handleEndLoading,
      "Stepper Previous": handleStepperPrevious,
      "Stepper Next": handleStepperNext,
      "Select Stepper": handleSetStepperIndex,
      "Refresh Views": handleRefreshViews,
      "Reset Form": handleResetForm,
      Confirm: handleConfirmAction,
      Cancel: handleCancelAction,
      "Trigger Data Source": handleTriggerDataSource,
      "Run Validation": handleRunValidation,
      "Check Dirty": handleCheckDirty,
      "Next Page": handleNextPageAction,
      "Previous Page": handlePreviousPageAction,
      "Stop Workflow": stopWorkflow,
      "Clear Validation": handleClearValidation,
      "Fetch Next Page": fetchNextPage,
      "Open Modal Action": () => handleOpenModalAction(action),
    };

    const handler = handlers[action?.actionSourceType?.type];
    if (handler) {
      await handler(action, e, viewsState);
    }
  };

  async function executeChain(e: any, _viewsState?: any) {
    let actions: any[] = actionsMap?.default || [];
    // reset the cancel work flow error
    cancelWorkflow = false;
    dirtyConfirmationDenied = false;
    isConfirmationDenied = false;
    validationError = false;

    let mappedActionKey: string;

    const isTableAction: boolean = !_.isEmpty(tableAction?.action);
    if (!isChainMapped) {
      mappedActionKey = "default";
      actions = actionsMap?.default || [];
      if (props?.action && _.isEmpty(actionsMap)) {
        actions = [{ actionConfig: props?.action }, ...actions];
      }
      if (_.isEmpty(actionsMap) && tableAction?.action?.actionSourceType) {
        actions = [{ actionConfig: tableAction?.action }, ...actions];
      }
    } else {
      const resolvedActionsKey = replaceDataPlaceholders({
        queryString: actionsKey,
        item,
        viewsState,
        pageId,
        formData,
        __data,
        env: currentApp?.env,
        selectedRows,
        index,
        actionIndex,
        actions,
        pageIdFromLayout,
      });
      mappedActionKey = resolvedActionsKey;

      const condition = element?.config?.conditionActionMapKey || conditionKeyFromTable || "equal";
      if (condition === "equal" && actionsMap?.[resolvedActionsKey]) {
        actions = actionsMap?.[resolvedActionsKey];
      } else if (condition === "startWith") {
        const matchingKey = Object.keys(actionsMap).find(key => resolvedActionsKey?.startsWith(key));
        if (matchingKey) {
          actions = actionsMap?.[matchingKey];
        }
      }
    }

    if (!_.isEmpty(actions) && e) {
      // reset .response for the whole workflow action
      if (_key) {
        setValue(`${pageId}.${viewName}.${_key}.response`, null, { pageId, viewName });
      }
      e.stopPropagation();
    }

    actions = actions.filter(action => Object.keys(action?.actionConfig)?.length !== 0);
    if (isLoadingForEntireChain?.[mappedActionKey]) {
      actions = actions.filter(action => !action?.actionConfig?.actionSourceType?.type?.includes("Loading"));
      handleStartLoading?.();
    }

    for (let i = 0; i < actions?.length; i++) {
      const action = actions?.[i]?.actionConfig;
      const actionCondition = replaceDataPlaceholders({
        queryString: action.actionCondition ?? action.condition,
        actionResponse: _.get(viewsState, `${pageId}-${viewName}.response.${_key}`),
        item,
        viewsState,
        pageId,
        formData,
        __data,
        env: currentApp?.env,
        selectedRows,
        selector: dataEntry ? `this.data.${dataEntry}[*]` : `this.data._body[*]`,
        index,
        actionIndex,
        actions,
        pageIdFromLayout,
      });

      try {
        if (!eval(actionCondition) && !_.isEmpty(actionCondition)) {
          continue;
        }
      } catch (error) {
        console.error("Expression throws an exception");
      }

      await handleActionMapper(action, e, _viewsState || viewsState);

      if (isConfirmationDenied) {
        break;
      }

      if (validationError && (action.abort ?? true)) {
        break;
      }

      if (dirtyConfirmationDenied) {
        break;
      }

      //Store response state for the current action, if any
      const currentActionResponse = getValue(`${pageId}.${viewName}.${_key}.response`, { pageId, viewName });
      setValue(`${pageId}.${viewName}.${_key}.responses[${i}]`, currentActionResponse, { pageId, viewName });

      if (isTableAction) {
        const isActionOnRowLevel = !tableAction?.action.isGlobal;

        const actionResponsePath = isActionOnRowLevel
          ? `${pageId}.${viewName}.actions[${actionIndex}].rows[${index}].response`
          : `${pageId}.${viewName}.actions[${actionIndex}].response`;

        const actionResponsesPath = isActionOnRowLevel
          ? `${pageId}.${viewName}.actions[${actionIndex}].rows[${index}].responses[${i}]`
          : `${pageId}.${viewName}.actions[${actionIndex}].responses[${i}]`;

        const currentActionResponse = getValue(actionResponsePath, { pageId, viewName });
        setValue(actionResponsesPath, currentActionResponse, { pageId, viewName });
      }

      if (cancelWorkflow && (action.abort ?? true)) {
        // enqueueSnackbarRef?.("Workflow aborted", { variant: "error" });
        handleEndLoading?.();
        break;
      }

      // enqueueSnackbarRef?.("Workflow executed successfully", { variant: "success" });
    }
    if (isLoadingForEntireChain?.[mappedActionKey]) {
      handleEndLoading?.();
    }
  }

  const onClickHandler = useCallback(
    e => {
      if (isComponentDisabled || componentVisibility == "hidden" || componentVisibility == "none") {
        e.stopPropagation();
        return;
      }
      // For old action version
      if (!interactionConfig) {
        executeChain(e);
        return;
      }

      if (currentRepeatedItem && onSelectRow) {
        onSelectRow?.(currentRepeatedItem);
      }

      const selfTriggers = interactionConfig?.filter(interaction => interaction.type === "OnClick" && interaction?.triggerSelf);
      selfTriggers?.forEach(_ => executeChain(e));
    },
    [interactionConfig, currentRepeatedItem, onSelectRow]
  );

  return {
    executeChain,
    onClickHandler,
    isLoading,
    isViewActionTriggered,
    jsonProgress,
    setIsViewActionTriggered,
    setActionView,
    setSelectedView,
    proceedToNextAction,
    actionModal,
    currentApp,
    viewsState,
    setShowModalForAction,
    setActionModal,
    showModalForAction,
    actionConfirmControl,
    handleCancelAction,
    handleConfirmAction,
    open,
    handleClose,
    isConfirmAction,
    actionView,
    selectedView,
    onAction,
  } as any;
};
